import getAuthorizeSsoSettings from '@/api/corporate/authorize/ssoSettings/get'
import getGsoToken from '@/api/corporate/authorize/gsoToken/get'
import Session from '@/models/Session/app'
import { setHeader, removeHeader } from '@/api/APIHandler'
import jwtDecode from 'jwt-decode'
import router from '@/router'
import Cookies from 'js-cookie'
import SessionLogPayload from '@/models/Session/sessionLog'
import LogRocket from 'logrocket'

export default {
  async checkGso({ commit, dispatch, getters, rootGetters, rootState }) {
    const xeConsentState = rootState.consent.consentState
    // Initialize analytics provider based on MKT consent
    if (xeConsentState.marketing) {
      dispatch(
        'analytics/init',
        {
          segmentKey: config.VUE_APP_KEYS_SEGMENT,
          amplitudeKey: config.VUE_APP_KEYS_AMPLITUDE,
          gtmKey: config.VUE_APP_KEYS_GTM,
        },
        { root: true }
      )
    }

    const settings = await getAuthorizeSsoSettings.exec()

    commit('setLoginUrl', config.VUE_APP_URL_LOGIN_URI)
    commit('setTokenUrl', config.VUE_APP_URL_TOKEN_URI)
    commit('setIsLiteRegistration', settings.data.isLiteRegistration)
    try {
      const { data } = await getGsoToken.exec(config.VUE_APP_URL_TOKEN_URI)
      if (data && data.accessToken) {
        // Saves the token on the request
        commit('setToken', data.accessToken)
        commit('setRefreshToken', data.refreshToken)
        setHeader('Authorization', `Bearer ${data.accessToken}`)
        const decoded = jwtDecode(data.accessToken)

        // Remove unnecessary store values
        if (rootGetters['registration/getRegistrationCompleted']) {
          dispatch('registration/resetForm', null, { root: true }) // Reset registration store
          dispatch('registration/setRegistrationCompleted', false, { root: true })
        }

        // User logged in but it's registration has finished
        if (decoded && decoded.profile && decoded.profile.clientNumber) {
          // Enables redirect to dashboard
          await commit('setProfileId', decoded.profile.profileId)
          const allowedInDashboard = await dispatch('dashboard/allowedInDashboard', null, {
            root: true,
          })
          if (allowedInDashboard) {
            if (router?.history?.current?.matched[0]?.path !== '/dashboard') {
              router.push({ name: 'dashboard' })
              // Stops Calls after redirected
              return false
            }
          } else {
            dispatch('dashboard/setClearSessionStore', null, { root: true })
            return false
          }
        } else if (decoded && decoded.email) {
          if (getters['getLastLogin'] !== decoded.email) {
            commit('setLastLogin', decoded.email)
            dispatch('corporateRegistration/resetForm', null, { root: true })
          }
        } else {
          commit('setLastLogin', null)
          commit('setProfileId', null)
        }

        // To fill the country prior to update the user
        await dispatch('device/checkDeviceCountry', null, { root: true })
        // Updates the user with the country information
        const profileData = await dispatch('corporateRegistration/updateProfile', null, {
          root: true,
        })
        if (profileData) {
          await commit('setProfileId', profileData.profileId)
        }
        // Handles identify call
        const profileId = getters.getProfileId || ''
        const country = rootGetters['device/getCountry'] || ''
        const sessionLogPayload = new SessionLogPayload({ profileId, country })
        LogRocket.identify(`${sessionLogPayload.userId}`, sessionLogPayload)
      }
    } catch (ex) {
      // Reads cookie dropped by GSO
      const xe_redirect_helper = Cookies.get('xe_redirect_helper')
      if (!xe_redirect_helper) {
        // No expiry so will automatically be a session cookie.
        Cookies.set('xe_redirect_helper', 'true')
      }

      // Log the error
      const statusCode = ex.response.status
      dispatch(
        'app/logException',
        {
          text: 'Exception during authentication process',
          exception: ex,
        },
        { root: true }
      )
      //This is to identify users has come to signup with MoneyTransfer account, but failing to get GsoToken
      //due to 2FA not done - so redirecting them to Fxweb to go through 2FA.
      if (statusCode === 401 && xe_redirect_helper) {
        Cookies.remove('xe_redirect_helper')
        window.removeEventListener('beforeunload', window.handleWindowClose)
        window.location.href = config.VUE_APP_URL_TRANSFER
        return false
      }

      window.location.href = config.VUE_APP_URL_LOGIN_URI
      return false
    }
    return true
  },
  updateToken({ commit }, newToken) {
    commit('setToken', newToken)
  },
  setToken({ commit, rootGetters }, loginResponse) {
    // TODO: improve put together with session store ?
    const jwt = jwtDecode(loginResponse.token)
    const session = new Session(jwt)
    commit('session/setSession', session, { root: true })
    setHeader('Locale', session.locale)
    setHeader('IsoCode', session.isoCode)
    setHeader('CultureCode', session.cultureCode)

    // Find the country ID base off of the IsoCode and set it to the auth store
    const country = rootGetters['countries/getCountryByIsoCode'](session.isoCode)
    commit('device/setCountryId', country.id, { root: true })

    // Set token to store
    commit('setToken', loginResponse)
    // Set Axios instance Auth Header
    setHeader('Authorization', `${loginResponse.tokenType} ${loginResponse.token}`)
  },
  async logout({ dispatch }) {
    // Removes event listener on logout
    window.removeEventListener('beforeunload', window.handleWindowClose)
    await dispatch('logoutFromLaunchpad')
  },
  reset({ commit }) {
    // Remove Axios instance Auth Header
    removeHeader('Authorization')
    // Reset store state
    commit('reset')
  },
  sendToContinueAs({ getters }) {
    window.removeEventListener('beforeunload', window.handleWindowClose)
    const continueAsUrl = getters['getLoginUrl'].replace('signup', 'continueAs')
    window.location.href = continueAsUrl
  },
  async logoutFromFxWeb({ getters, dispatch }) {
    // fxweb logout
    const transUrl = config.VUE_APP_URL_TRANSFER_LOGOUT
    await fetch(transUrl, { credentials: 'include', mode: 'no-cors' })
    let loginUrl = getters['getLoginUrl']
    loginUrl = loginUrl.replace('signup', 'logout')
    dispatch('reset')
    dispatch('session/setSessionId', null, {
      root: true,
    })

    window.location.href = loginUrl
  },
  async logoutFromLaunchpad({ dispatch }) {
    const accountSiteUrl = config.VUE_APP_ENDPOINTS_ACCOUNT_SITE

    try {
      const launchpadLogoutUrl = config.VUE_APP_ENDPOINTS_LAUNCHPADAPI + 'logout'
      //We also need to logout from Fxweb as Fxweb session might be lingering around
      const transferLogoutUrl = config.VUE_APP_URL_TRANSFER_LOGOUT
      await Promise.all([
        fetch(transferLogoutUrl, { credentials: 'include', mode: 'no-cors' }),
        fetch(launchpadLogoutUrl, { credentials: 'include', mode: 'no-cors' }),
      ])
      dispatch('reset')
      dispatch('session/setSessionId', null, {
        root: true,
      })

      window.location.href = accountSiteUrl
    } catch (error) {
      // Error handler
      dispatch(
        'app/logException',
        {
          text: 'Unable to logout from Launchpad',
          exception: error,
        },
        { root: true }
      )
    }
  },
  async exitRegistration({ dispatch }) {
    await window.removeEventListener('beforeunload', window.handleWindowClose)
    // Clear registration store data
    await dispatch('corporateRegistration/resetForm', {}, { root: true })
  },

  //If users are still in the registration process (before account created), we can just logout from Launchpad
  //There won't be Fxweb sessions
  async leaveRegistration({ dispatch }) {
    try {
      await dispatch('exitRegistration')
      const launchpadLogoutUrl = config.VUE_APP_ENDPOINTS_LAUNCHPADAPI + 'logout'

      await fetch(launchpadLogoutUrl, { credentials: 'include', mode: 'no-cors' })
      dispatch('reset')
      dispatch('session/setSessionId', null, {
        root: true,
      })
      window.location.href = config.VUE_APP_ENDPOINTS_ACCOUNT_SITE
    } catch (error) {
      dispatch(
        'app/logException',
        {
          text: 'Unable to logout while leaving the registration',
          exception: error,
        },
        { root: true }
      )
    }
  },
}

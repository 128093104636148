export default {
  steps: [
    {
      title: 'Account',
    },
    {
      title: 'Verification',
    },
    {
      title: 'Business',
      path: '/',
      pageTitle: 'Business information',
    },
    {
      title: 'Personal',
      path: '/personal-details',
      pageTitle: 'Personal details',
    },
  ],
  activeStepIdx: 0,
  phoneDetails: {
    number: '',
    areaCode: '',
    nameAbbr: '',
  },
  userGeoCountry: 'GB',
  countryList: [],
  stateList: [],
  streetTypeList: [],
  supportCountryList: [],
  natureOfBusinessList: [],
  subIndustryList: [],
  businessDetail: {
    companyName: '',
    businessDifferentName: false,
    ein: '',
    website: '',
    natureOfBusiness: '',
    signinAuthority: false,
    tradingName: '',
  },
  personalAddress: {
    id: '',
    language: '',
    buildingName: '',
    buildingNumber: '',
    subBuilding: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    street: '',
    city: '',
    district: '',
    countryCode: '',
    postCode: '',
    provinceName: '',
  },
  personalDetail: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  businessSameOperatingAddress: false,
  newCustomerRequiredFields: null,
  mockedCustomerRequiredFields: null,
  corporateForm: {},
  userId: '',
  companyTypeName: '',
  promptForMarketingConsent: false,
  marketingOptIn: false,
  clientNumber: '',
  currencyTypeList: [],
  saveFormFields: [],
}

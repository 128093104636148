export default {
  getCompanyCountry(state) {
    return state.companyCountry
  },
  getCompanyType(state) {
    return state.companyType
  },
  getCompanyTypeList(state) {
    return state.companyTypeList
  },
  getSigningAuthority: (state) => {
    return state.signingAuthority
  },
  getHasCompanyTypeChanged: (state) => {
    return state.hasCompanyTypeChanged
  },
}

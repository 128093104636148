import { helpers } from '@vuelidate/validators'
import { PhoneDetails } from '@/models/Registration'
import { getRegionFromCountry } from '@/utils/i18n'

export default {
  getSubmitRegistration: (state) => {
    return state.isSubmitRegistration
  },
  getCountryOfResidence: (state, getters, rootState) => {
    const { countryOfResidenceId } = state.form
    if (!helpers.req(countryOfResidenceId)) {
      return rootState.device.countryId
    }
    return countryOfResidenceId
  },
  getCountryOfResidenceNameAbbr: (state, getters, rootState, rootGetters) => {
    const id = getters.getCountryOfResidence
    const country = rootGetters['countries/getCountryById'](id)
    return country.nameAbbr
  },
  getPhoneDetails: (state, getters, rootState, rootGetters) => {
    const phoneDetails = state.form.phoneDetails
    if (!phoneDetails) {
      const countryId = getters.getCountryOfResidence
      const country = rootGetters['countries/getCountryById'](countryId)
      return PhoneDetails.FromCountry(country)
    }
    const nameAbbr = rootGetters['countries/getCountryById'](phoneDetails.countryId).nameAbbr
    return new PhoneDetails({
      ...phoneDetails,
      nameAbbr,
    })
  },
  getPhoneNumber: (state) => {
    return state.form.phoneNumber
  },
  getInfoFormField: (state) => (fieldId) => {
    const infoFormFieldItem = state.form.infoForm[fieldId]
    return {
      value: infoFormFieldItem ? infoFormFieldItem.value : '',
    }
  },
  getStpConfig: (state) => {
    return state.stpConfig
  },
  getRegistrationStepConfigById: (state) => (id) => {
    if (state.stpConfig) {
      const registrationStep = state.stpConfig.registrationFlow.find((group) => group.id === id)
      return registrationStep || {}
    }
    return {}
  },
  getRegistrationStepConfig: (state) => {
    return state.stpConfig.registrationFlow
  },
  isStpAllowedCountry: (_state, _getters, _rootState, rootGetters) => (country) => {
    // Check registered country
    const deviceCountry = country
    // Check for allowed countries
    const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](deviceCountry)
    // Return if country is allowed
    return countryConfig?.allowedSTPCountry || false
  },
  isStpAllowCompanyType: (_state, getters, _rootState, rootGetters) => (country, companyType) => {
    // Get allowed company type
    const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](country)
    // Return if company type is allowed
    return countryConfig.allowedCompanyTypesForStp.includes(
      companyType ? companyType : rootGetters['corporateRegistration/companyDetails/getCompanyType']
    )
  },
  formFilledInformationAllowsStpFlow: (_state, getters, _rootState, rootGetters) => {
    // We use this value on business-type screen to validate if the user can continue registering their combination of country/company type
    const selectedCountry = rootGetters['corporateRegistration/companyDetails/getCompanyCountry']
    const selectedCompanyType = rootGetters['corporateRegistration/companyDetails/getCompanyType']
    const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](selectedCountry)
    return countryConfig?.allowedSTPCountry
      ? countryConfig.allowedCompanyTypesForStp.includes(selectedCompanyType)
      : false
  },
  getRedirectToOldFlow: (state) => {
    return state.redirectToOldFlow
  },
  getFormattedAddress: (state) => {
    return state.formattedAddress
  },
  getRegistrationCompleted: (state) => {
    return state.registrationCompleted
  },
  getStepOptions: (_state, getters, _rootState, rootGetters) => {
    if (getters.getRedirectToOldFlow) {
      return [
        {
          title: 'Account',
        },
        {
          title: 'Verification',
        },
        {
          title: 'Business',
          path: '/',
          pageTitle: 'Business information',
        },
        {
          title: 'Personal',
          path: '/personal-details',
          pageTitle: 'Personal details',
        },
      ]
    } else {
      return rootGetters['registration/getStpConfig'].registrationSteps
    }
  },
  getIsSoleCompanyType: (_state, _getters, _rootState, rootGetters) => {
    const country = getRegionFromCountry(country)
      ? getRegionFromCountry(country)
      : rootGetters['corporateRegistration/companyDetails/getCompanyCountry']
    const companyType = rootGetters['corporateRegistration/companyDetails/getCompanyType']
    const allowedForMkPrompt =
      rootGetters['countries/getCountryConfigByCountryCode'](country)?.marketingPromptCompanies
    return allowedForMkPrompt ? allowedForMkPrompt.includes(companyType) : false
  },
  getCountryOrRegionIsAllowedForStp(_state, _getters, _rootState, rootGetters) {
    const country = getRegionFromCountry(country)
      ? getRegionFromCountry(country)
      : rootGetters['corporateRegistration/companyDetails/getCompanyCountry']
    const companyType = rootGetters['corporateRegistration/companyDetails/getCompanyType']
    const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](country || '')
    if (countryConfig) {
      // Check company type
      const isAllowedCompanyType =
        countryConfig?.allowedCompanyTypesForStp?.includes(companyType) || false

      // Return condition
      return ((countryConfig?.allowedSTPCountry || false) && isAllowedCompanyType) || false
    }
  },
  getRegistrationConfig(state) {
    return state.registrationConfig
  },
}

// There is no Level text input, but we capture it to just throw it away
// Test at https://regex101.com/
const re = /^(?<level>(?:(\w+\s*)*(Level|L))+\s*\d+\s*)?(?<unitNo>(?:(Suite|Unit))?\s*\d*\s*)-?(?<streetNo>(\d*)\s*)(?<streetName>(\w+\s*)*)$/

const getAdressLine = (object, property) => object[property] ? object[property].replace(',', ' ').trim() : ''

const matchRegexGroup = addressLine => addressLine.match(re).groups

const setDataFields = (addressObject) => {
  let { unit, streetname, streetnumber } = addressObject

  const groups1 = matchRegexGroup(getAdressLine(addressObject, 'addressLine1'))
  const groups2 = matchRegexGroup(getAdressLine(addressObject, 'addressLine2'))
  const groups3 = matchRegexGroup(getAdressLine(addressObject, 'addressLine3'))

  unit = ''
  streetnumber = ''
  streetname = ''

  unit = (groups1.unitNo || groups2.unitNo || groups3.unitNo).trim()
  streetnumber = (groups3.streetNo || groups2.streetNo || groups1.streetNo).trim()
  streetname = (groups3.streetName || groups2.streetName || groups1.streetName).trim()

  //regex doesnt capture this case, need to manually switch streetnumber with unit
  if (!streetnumber && unit) {
    streetnumber = unit
    unit = ''
  }
  return {
    ...addressObject,
    unit,
    streetname,
    streetnumber
  }
}
export const fixStreetAddressAU = addressObject => setDataFields(addressObject)

/* eslint-disable */

import { toRefs, watch } from '@vue/composition-api'
import newCustomerRequiredFields from '@/api/corporate/corporateRegistration/post'
import countries from '@/api/corporate/countries/get'
import getStates from '@/api/corporate/countries/states/get'
import getStreetTypes from '@/api/corporate/streetTypes/get'
import addressSearch from '@/api/corporate/addressSearch/get'
import addressDetails from '@/api/corporate/addressDetails/get'
import getCorporateRegistration from '@/api/corporate/corporateRegistration/get'
import getTermsAndConditions from '@/api/corporate/termsConditions/get'
import putTermsAndConditions from '@/api/corporate/termsConditions/put'
import putMarketingPreferenceConsent from '@/api/corporate/marketingPreferenceConsent/put'
import submitFields from '@/api/corporate/submitFields/put'
import submitRegistration from '@/api/corporate/submitRegistration/_id/post'
import getNatureOfBusiness from '@/api/corporate/natureOfBusiness/get'
import getSubIndustry from '@/api/corporate/natureOfBusiness/subIndustry/get'
import postAuthorizeProfile from '@/api/corporate/authorize/profile/post'
import getGsoToken from '@/api/corporate/authorize/gsoToken/get'
import { setHeader } from '@/api/APIHandler'
import getCurrencyType from '@/api/corporate/currencyType/get'
import getMarketingPrompt from '@/api/corporate/marketingPreferenceConsent/marketingPrompt/get'
import mockedCustomerRequiredfields from '@/data/registration/corporateRegistration'
import CorporateFieldGroup from '@/models/CorporateRegistration/CorporateFieldGroup/app'
import { getMappedCountry } from '@/utils/i18n'

export default {
  setActiveStep({ commit, getters }, path) {
    const step = getters.getStepByPath(path)
    const idx = getters.getStepIdx(step)
    commit('setActiveStepIdx', idx)
  },
  setActiveStepById({ commit }, idx) {
    commit('setActiveStepIdx', idx)
  },
  setActiveStepPageTitle({ commit }, title) {
    commit('setActiveStepPageTitle', title)
  },
  setPhoneDetails({ commit }, phoneDetails) {
    commit('setPhoneDetails', phoneDetails)
  },
  setBusinessDetail({ commit }, businessDetail) {
    commit('setBusinessDetail', businessDetail)
  },
  setPersonalAddress({ commit }, personalAddress) {
    commit('setPersonalAddress', personalAddress)
  },
  setPhoneNumber({ commit }, phoneNumber) {
    commit('setPhoneNumber', phoneNumber)
  },
  setCompanyTypeName({ commit }, companyTypeName) {
    commit('setCompanyTypeName', companyTypeName)
  },
  setSteps({ commit }, steps) {
    commit('setSteps', steps)
  },
  async getNewCustomerRequiredFields({ commit, getters, rootGetters }, setId = true) {
    const { id, data } = await newCustomerRequiredFields.exec({
      data: {
        country: getters['companyDetails/getCompanyCountry'],
        companyType: getters['companyDetails/getCompanyType'],
      },
    })
    if (data) {
      commit('setNewCustomerRequiredFields', data)
    }
    if (setId && id) {
      commit('setUserId', id)
      const login = rootGetters['auth/getLastLogin']
      if (login) {
        commit('device/setRegistrationIdForUser', { user: login, id }, { root: true })
      }
    }
  },
  clearCustomerFieldsGroup({ commit }, group) {
    commit('setClearCustomerFieldsGroup', group)
  },
  async getMockedCustomerRequiredfields({ commit }) {
    const data = mockedCustomerRequiredfields.requirements.map((fieldGroup) => new CorporateFieldGroup(fieldGroup))
    if (data) {
      commit('setMockedCustomerRequiredFields', data)
    }
  },
  async getCountryList({ commit }) {
    const { data } = await countries.exec()
    if (data) {
      const sorted = data.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        }
        return 0
      })
      commit('setCountryList', sorted)
    }
  },
  async getAllCountryList({ commit }) {
    const { data } = await countries.exec({ all: true })
    if (data) {
      commit('setAllCountryList', data)
    }
  },
  async getStateList({ commit }, country) {
    const { data } = await getStates.exec(getMappedCountry(country))
    if (data) {
      commit('setStateList', data)
    }
  },
  async getStreetTypeList({ commit }, country) {
    const { data } = await getStreetTypes.exec(getMappedCountry(country))
    if (data) {
      commit('setStreetTypeList', data)
    }
  },
  async getAddressSearchResult(context, { searchTerm, country, searchContext }) {
    const { data } = await addressSearch.exec({ searchTerm, country, searchContext })
    if (data) {
      return data
    }
  },
  async getAddressDetails(context, { addressId }) {
    const { data } = await addressDetails.exec({ addressId })
    if (data) {
      return data
    }
  },
  async saveFormFields({ commit }, { group, formFields = null, customFields = null }) {
    // Save form fields
    if (formFields) {
      formFields = formFields?.value || formFields
      formFields.map(({ id, value }) => {
        if (!value) {
          value = ''
        }
        commit('setSaveFormFields', { group, id, raw: value })
      })
    }
    // Save custom fields
    if (customFields) {
      customFields.map(({ id, value }) => {
        commit('setSaveFormFields', { group, id, raw: value })
      })
    }
  },
  async submitFieldData({ commit, getters }, { group, formFields, extraFields = null, send = true }) {
    if (formFields.value) {
      formFields = formFields.value
    }
    const fields = formFields.map(({ id, value }) => {
      if (!value) {
        value = ''
      }
      return { group, id, raw: value }
    })
    if (extraFields) {
      for (const key in extraFields) {
        const value = extraFields[key] || ''
        fields.push({ group, id: key, raw: value })
      }
    }

    if (send) {
      const id = getters.getUserId
      const result = await submitFields.exec({ id, fields })
      const errors = result.data
      if (errors && errors.length > 0) {
        return errors
      }
    }

    commit('setCorporateFormFieldValue', { name: group, formData: formFields })
    return null
  },
  // Send all field saved on the store
  async submitAllFieldsData({ commit, getters }, formFields) {
    const id = getters.getUserId
    const result = await submitFields.exec({ id, fields: formFields })
    const errors = result.data
    if (errors && errors.length > 0) {
      return errors
    }
    return null
  },
  async submitRegistration({ commit, getters, rootGetters }) {
    const id = getters.getUserId
    const countryFromIp = rootGetters['device/getCountry']
    const { data, promptForMarketingConsent, clientNumber } = await submitRegistration.exec({
      id,
      countryFromIp,
    })
    commit('setMarketingConsentPopup', promptForMarketingConsent)
    commit('setClientNumber', clientNumber)
    return data
  },
  async getNatureOfBusinessList({ commit }) {
    const { data } = await getNatureOfBusiness.exec()
    if (data) {
      commit('setNatureOfBusinessList', data)
    }
  },
  async setSubIndustryList({ commit }, categoryId) {
    if (categoryId) {
      // Cleans the sub industry list first before updating it
      commit('setSubIndustryList', [])
      const { data } = await getSubIndustry.exec(categoryId)
      commit('setSubIndustryList', data)
    }
  },
  async checkRedirect({ rootGetters, dispatch }, router) {
    const search = '?corporate-registration='
    if (window.location.href.indexOf(search) !== -1) {
      const parts = window.location.href.split(search)
      if (parts.length > 1) {
        await dispatch('redirectRegistrationId', { registrationId: parts[1], router })
        return true
      }
    }

    const login = rootGetters['auth/getLastLogin']
    if (login) {
      const id = rootGetters['device/getCorporateRegistrationIdForUser'](login)
      if (id) {
        try {
          await dispatch('redirectRegistrationId', { registrationId: id, router })
        } catch (ex) {
          dispatch(
            'app/logException',
            {
              text: 'Exception during checking registration ID',
              exception: ex,
            },
            { root: true }
          )
          const rootUrl = window.location.protocol + '//' + window.location.host + '/registration/business-type'
          if (!rootGetters['companyDetails/getCompanyType'] && window.location.href !== rootUrl) {
            window.location.href = rootUrl
            return false
          }
        }
      }
    }
    return true
  },
  async redirectRegistrationId({ commit, getters, dispatch }, { registrationId, router }) {
    const { data } = await getCorporateRegistration.exec(registrationId)
    if (data.corporateForm) {
      const country = data.corporateForm['registeredaddress']['country'].value
      await dispatch('resetForm')
      commit('companyDetails/setCompanyCountry', country)
      commit('companyDetails/setCompanyType', data.corporateForm['company']['companytype'].value.toString())

      const promises = []
      promises.push(dispatch('getNewCustomerRequiredFields', false))

      // IMPROVE: ideally we don't need them here
      promises.push(dispatch('corporateRegistration/getStateList', country, { root: true }))
      promises.push(dispatch('corporateRegistration/getStreetTypeList', country, { root: true }))
      promises.push(dispatch('corporateRegistration/getNatureOfBusinessList', null, { root: true }))
      await Promise.all(promises)

      commit('setCorporateForm', data.corporateForm)
      const phoneDetails = {
        number: '',
        areaCode: '',
        nameAbbr: '',
      }
      if (data.corporateForm['contact'] && data.corporateForm['contact']['phonenumber']) {
        const countryList = getters['getCountryList']
        phoneDetails.areaCode = data.corporateForm['contact']['phoneprefix'].value
        if (phoneDetails.areaCode) {
          phoneDetails.areaCode = phoneDetails.areaCode.replace('+', '')
        }
        phoneDetails.number = phoneDetails.areaCode + '-' + data.corporateForm['contact']['phonenumber'].value
        const phoneCountries = countryList.filter((phoneCountry) => phoneCountry.phonePrefix === phoneDetails.areaCode)
        if (phoneCountries && phoneCountries.length > 0) {
          phoneDetails.nameAbbr = phoneCountries[0].code
          if (phoneCountries.length > 1) {
            const phoneCountry = phoneCountries.find((phoneCountry) => phoneCountry.code === country)
            if (phoneCountry) {
              phoneDetails.nameAbbr = phoneCountry.code
            }
          }
        }
      }
      commit('setPhoneDetails', phoneDetails)
      commit('setUserId', registrationId)

      // if not already on a business page, redirect to the latest one
      if (window.location.href.indexOf('/') === -1) {
        if (data.corporateForm['contactaddress']) {
          router.push({ name: 'PersonalAddress' })
        } else if (data.corporateForm['contact']) {
          router.push({ name: 'RegisterPersonalDetails' })
        } else if (data.corporateForm['tradingaddress']) {
          router.push({ name: 'OperatingAddress' })
        } else if (data.corporateForm['registeredaddress'] && data.corporateForm['registeredaddress']['city']) {
          router.push({ name: 'BusinessAddress' })
        } else if (data.corporateForm['company'] && data.corporateForm['company']['registeredname']) {
          router.push({ name: 'RegisterBusinessDetails' })
        } else if (data.corporateForm['company']) {
          router.push({ name: 'RegisterBusinessSearch' })
        } else {
          router.push({ name: 'RegisterBusinessInformation' })
        }
      }
    }
  },
  async getTermsAndConditions({ getters }) {
    const { data } = await getTermsAndConditions.exec(getters['companyDetails/getCompanyCountry'])
    return data
  },
  async agreeTermsAndConditions(_context, version) {
    const { data } = await putTermsAndConditions.exec(version)
    return data
  },
  async updateProfile({ getters, rootGetters }) {
    const result = await postAuthorizeProfile.exec({
      country: getters['companyDetails/getCompanyCountry'],
    })
    if (result && result.data && result.data.refreshToken) {
      const tokenResult = await getGsoToken.exec(rootGetters['auth/getTokenUrl'])
      if (tokenResult && tokenResult.data && tokenResult.data.accessToken) {
        setHeader('Authorization', `Bearer ${tokenResult.data.accessToken}`)
      }
    }
    return result.data
  },
  async contactUs() {
    window.location = 'https://www.xe.com/contact/faq/' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  },
  showServerErrors(_context, { errors, formFields }) {
    for (const err of errors) {
      const { id, errors } = err
      const descriptions = errors.map((err) => err.description)
      const field = formFields.value.find((formField) => formField.id === id)
      if (field) {
        field.serverError = descriptions.join('\r\n')
        const stop = watch(toRefs(field).value, () => {
          field.serverError = null
          stop()
        })
      }
    }
  },
  proceedRegistration({ rootGetters }) {
    window.location = rootGetters['corporateRegistration/getRedirectUrl']
  },
  setBusinessSameOperatingAddress({ commit }, businessSameOperatingAddress) {
    commit('setBusinessSameOperatingAddress', businessSameOperatingAddress)
  },
  async updateMarketingPreferences(_context, consentedToMarketing) {
    await putMarketingPreferenceConsent.exec({
      consentedToMarketing,
      promptForMarketingConsent: false,
    })
  },
  async getMarketingPrompt({ commit }, { countryCode, companyTypeId }) {
    const result = await getMarketingPrompt.exec({ countryCode, companyTypeId })
    commit('setMarketingConsentPopup', result.data)
  },
  mapStateValue({ getters }, stateName) {
    const states = getters['getStateList']
    if (stateName && states && states.length) {
      stateName = stateName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      const state = states.find((item) => item.name === stateName)
      if (state) {
        return state.value
      }
    }
    return stateName
  },
  setMarketingOptIn({ commit }, marketingOptIn) {
    commit('setMarketingOptIn', marketingOptIn)
  },
  resetForm({ commit, dispatch }) {
    commit('resetForm')
    dispatch('companyDetails/resetForm')
  },
  async getCurrencyTypeList({ commit }, { country }) {
    const { data } = await getCurrencyType.exec({ country })
    if (data) {
      commit('setCurrencyTypeList', data)
    }
  },
}

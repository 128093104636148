import { map, flow, sortBy } from 'lodash-es'

export default {
  offeredCountriesDialingCodesOptions(state) {
    return flow(
      // Sort them by countryCode
      (countries) => sortBy(countries, 'countryCode'),
      // Map to text value pair
      (countries) =>
        map(countries, (country) => {
          const countryCode = country.countryCode.trim()
          return {
            text: `+${countryCode} ${country.nameAbbr}`,
            value: countryCode,
            id: country.id,
          }
        })
    )(state.countries)
  },
  getCountryById: (state) => (id) => {
    return state.countries.find((country) => country.id === id)
  },
  getCountryByIsoCode: (state) => (isoCode) => {
    return state.countries.find((country) => country.nameAbbr === isoCode)
  },
  getOfferedCountries(state) {
    return state.offeredCountries
  },
  getCountryConfigByCountryCode: (state) => (countryCode) => {
    return state.countryConfigs.find((config) => config.defaultCountryFrom === countryCode)
  },
}

export default {
  consentState: {
    marketing: false,
    performance: false,
    compliance: false,
  },
  leadSourcesState: {
    dfxId: null,
    clientAffiliate: null,
  },
}

import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { isAddressExist } from './useUtils'
import store from '@/store'

//returns the first set of numbers
const extractDigit = (addressLine) => {
  const matches = addressLine.match(/\d+/)
  return matches && matches.length > 0 ? matches[0] : ''
}

const removeCharacter = (object, property) =>
  object[property].replace(/[^a-zA-Z\d\s\-.]/g, '').trim()

const setDataFields = async (addressObject) => {
  const corporateRegistrationStore = useCorporateRegistrationStore()

  let {
    addressLine1,
    addressLine2,
    addressLine3,
    unit,
    streetname,
    streetnumber,
    postCode,
    city,
    state,
  } = addressObject
  
  if (isAddressExist(addressLine1, addressLine2, addressLine3)) {
    // Case 1
    addressLine1 = `${removeCharacter(addressObject, 'addressLine1')} ${removeCharacter(
      addressObject,
      'addressLine2'
    )}`
    if (
      addressLine1.includes('Unit') ||
      addressLine1.includes('Suite') ||
      addressLine1.includes('Ste')
    ) {
      unit = addressLine1.match(/(Unit|Suite|Ste)+\s\d+/)[0]
      addressLine1 = addressLine1.replace(unit, '').trim()
      unit = extractDigit(unit)
    }

    // Case 1.1 - if Unit is separated by a ' - '
    if (addressLine1.includes(' - ')) {
      unit = addressLine1.match(/^.+\s-/)[0]
      addressLine1 = addressLine1.replace(unit, '').trim()
      unit = extractDigit(unit)
    }

    //If address is a PO Box, no formatting is needed. Keep it in street
    let isPoBox = addressLine1.toLowerCase().includes('po box')

    // //  Case 2
    if (!isPoBox) {
      let matches = addressLine1.match(/\d+/g)
      if (matches.length > 1) {
        streetnumber = matches[0]
        streetname = addressLine1.replace(streetnumber, '').trim()
      } else {
        if (addressLine1.match(/\d+/)) {
          streetnumber = addressLine1.match(/\d+/)[0]
          streetname = addressLine1.replace(streetnumber, '').trim()
        }
      }
    }

    // Formatting Postal Code
    if (postCode.split(' ').length > 2) {
      state = postCode.match(/^../)[0]
      postCode = postCode.replace(/^.../, '')
    }
    // Formatting City & State/Prov
    let cityAttr = city.split(',')
    if (cityAttr.length > 1) {
      let states = corporateRegistrationStore.stateList
      cityAttr[1] = cityAttr[1].trim()
      if (states.some((e) => e.name === cityAttr[1])) {
        state = await corporateRegistrationStore.mapStateValue(cityAttr[1])
        city = cityAttr[0]
      }
    }
  }
  return {
    ...addressObject,
    addressLine1,
    addressLine2,
    addressLine3,
    unit,
    streetname,
    streetnumber,
    postCode,
    city,
    state,
  }
}
export const fixStreetAddressCA = async (addressObject) => await setDataFields(addressObject)

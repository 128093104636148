import { isAddressExist } from "./useUtils"

const isCareOfExist = (addressLine) => addressLine.includes('C/o')

const findUnitKeywordIndex = (splittedAddressLine) => {
  const unitKeywords = ['Unit', 'Room', 'Suite', 'Flat']
  return splittedAddressLine.findIndex((eachKey) =>
    unitKeywords.some((keyword) => eachKey.includes(keyword))
  )
}

const findStreetnumberIndex = (splittedAddressLine) => {
  const regex = /.*[\d]+.*/
  return splittedAddressLine.findIndex((eachKey) => regex.test(eachKey))
}

const findLevelIndex = (splittedAddressLine) => {
  const levelKeyword = 'Level'
  return splittedAddressLine.findIndex((eachKey) =>
    eachKey.includes(levelKeyword)
  )
}

const extractFields = (addressLine) => {
  let unit = ''
  let streetnumber = ''
  let streetname = ''

  // convert to array
  const splittedAddressLine = addressLine.trim().split(' ')

  // remove keyword and level
  const levelIndex = findLevelIndex(splittedAddressLine)
  if (levelIndex >= 0) {
    splittedAddressLine.splice(levelIndex, 2)
  }

  // get unit, remove the keyword and unit
  const unitKeywordIndex = findUnitKeywordIndex(splittedAddressLine)
  if (unitKeywordIndex >= 0) {
    unit = splittedAddressLine
      .splice(unitKeywordIndex, 2)[1]
      .replaceAll(',', '')
  }

  // get streetnumber, remove the keyword and streetnumber
  const streetnumberIndex = findStreetnumberIndex(splittedAddressLine)
  if (streetnumberIndex >= 0) {
    streetnumber = splittedAddressLine
      .splice(streetnumberIndex, 1)[0]
      .toUpperCase()
      .replaceAll(',', '')
  }

  streetname = splittedAddressLine.join(' ')

  return {
    unit,
    streetnumber,
    streetname,
  }
}

export const fixStreetAddressNZ = (addressObject) => {
  const { addressLine1, addressLine2, addressLine3 } = addressObject

  if (isAddressExist(addressLine1, addressLine2, addressLine3)) {
    if (isCareOfExist(addressLine1)) {
      if (isAddressExist(addressLine2)) {
        return { ...addressObject, ...extractFields(addressLine2) }
      }
    }
    return { ...addressObject, ...extractFields(addressLine1) }
  }
  return addressObject
}

export default class PhoneDetails {
  constructor({ number, areaCode, countryId, nameAbbr }) {
    this.number = number
    this.areaCode = areaCode
    this.countryId = countryId
    this.nameAbbr = nameAbbr
    this.value = this.number.substr(this.number.indexOf('-') + 1)
  }

  static FromCountry(country) {
    const countryCode = country.countryCode.trim()
    return new PhoneDetails({
      number: countryCode,
      areaCode: countryCode,
      countryId: country.id,
      nameAbbr: country.nameAbbr,
    })
  }

  get numberOnly() {
    return this.number.replace(/-/g, '')
  }
}

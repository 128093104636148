<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>Find your business</h3>
      </AppCardHeader>
    </template>

    <div v-if="isLoadingOptions" class="text-center text-orange">
      <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
    </div>

    <div v-else>
      <XeInputDropdown
        id="companyname"
        v-debounce:800ms="onTextInput"
        data-public
        dropdown-label="Registered company legal name"
        option-label="companyName"
        :option-label-subtitle="setOptionLabelSubtitle"
        option-value="id"
        :show-clear="false"
        :options="companySearchResultList"
        placeholder="Enter company name or number"
        :input-editable="true"
        :show-arrow="false"
        :loading="showLoadingSpinner"
        :no-options-message="noOptionsMessage"
        type="company"
        @input="onOptionSelected"
      >
        <template #custom-dropdown-footer-option>
          <div v-if="searchPerformed" class="custom-dropdown-footer">
            Don't see your company?
            <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
            <a class="custom-footer-button" @click="onEnterManuallyClick('Dropdown')"
              >Enter it manually</a
            >
          </div>
        </template>
      </XeInputDropdown>
    </div>
  </AppCard>
</template>

<script>
import { onBeforeMount } from '@vue/composition-api'
import { reactive, ref } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useStore } from '@/composables/useStore'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { fixStreetAddress } from '@/composables/useBVDAddressFormatter'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'
import AppSpinner from '@/components/AppSpinner/AppSpinner'

import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'

import { storeToRefs } from 'pinia'

export default {
  name: 'RegisterBusinessSearch',
  components: {
    AppCard,
    AppCardHeader,
    AppButton,
    AppIcon,
    IconChevronLeft,
    XeInputDropdown,
    AppSpinner,
  },
  setup() {
    const isLoadingOptions = ref(true)
    const store = useStore()
    const router = useRouter()

    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()

    corporateRegistrationStore.activeStepIdx = 2
    corporateRegistrationStore.setActiveStepPageTitle('Find your business')

    const noOptionsMessageChoices = Object.freeze([
      'Please type at least 4 characters',
      'No results found',
    ])
    const companySearchResultList = ref([])
    const showLoadingSpinner = ref(false)
    const noOptionsMessage = ref(noOptionsMessageChoices[0])
    const searchPerformed = ref(false)
    const setOptionLabelSubtitle = ref('id')
    const { companyCountry } = storeToRefs(companyDetailsStore)

    onBeforeMount(async () => {
      if (!corporateRegistrationStore.userId) {
        await corporateRegistrationStore.fetchNewCustomerRequiredFields()
      }
      isLoadingOptions.value = false
    })

    /*
     * Editable dropdown sends input events for every text typed and option selected.
     * So this function will check from the search result list to see if it was selected.
     */
    const onOptionSelected = async (query) => {
      const selectedCompany = companySearchResultList.value.find((company) => company.id === query)
      if (selectedCompany) {
        companyDetailsStore.companyDetails = selectedCompany

        let taxNumber = selectedCompany.vatNumber

        let oldRegNumber = ''
        if (selectedCompany.id) {
          oldRegNumber = selectedCompany.nationalId
        }

        const companyFields = [
          { id: 'registeredname', value: selectedCompany.companyName },
          { id: 'ustaxnumber', value: taxNumber },
          {
            id: 'registrationnumber',
            value:
              selectedCompany.countryCode === 'MY' || selectedCompany.countryCode === 'NZ'
                ? oldRegNumber
                : selectedCompany.id,
          },
          {
            id: 'website',
            value:
              selectedCompany.website && selectedCompany.website.length > 0
                ? selectedCompany.website[0]
                : null,
          },
          { id: 'incorporationdate', value: selectedCompany.incorporationDate },
        ]

        const addressObject = await fixStreetAddress(companyCountry.value, selectedCompany)
        // set state to null if not mapped correctly
        const stateLength = addressObject.state ? addressObject.state.length : 0
        addressObject.state = stateLength <= 3 ? addressObject.state : null
        const [streetName, streetType] = extractStreetNameAndStreetType(addressObject.streetname)
        const addressFields = [
          // free-format address
          { id: 'line1', value: addressObject.addressLine1 },
          { id: 'line2', value: selectedCompany.addressLine2 },
          { id: 'line3', value: selectedCompany.addressLine3 },
          // fixed-format address
          { id: 'streetname', value: streetName },
          { id: 'streetnumber', value: addressObject.streetnumber },
          { id: 'streettype', value: streetType },
          { id: 'unit', value: addressObject.unit },
          { id: 'area', value: addressObject.area },
          // shared
          { id: 'city', value: addressObject.city },
          { id: 'state', value: addressObject.state },
          { id: 'postalcode', value: addressObject.postCode },
          { id: 'country', value: selectedCompany.countryCode },
        ]

        analyticsStore.track({
          event: 'Business Search Completed',
          traits: {
            enterManually: false,
            enterManuallyLocation: '',
            searchDuration: lastDuration,
            includesAddress:
              Object.hasOwn(selectedCompany, 'addressLine1') ||
              Object.hasOwn(selectedCompany, 'city') ||
              Object.hasOwn(selectedCompany, 'postCode'), // Get if search includes the address
            referenceSource: 'XEMT Business',
            email: authStore.lastLogin,
          },
        })
        await corporateRegistrationStore.submitFieldData({
          group: 'company',
          formFields: companyFields,
          send: false,
        })
        await corporateRegistrationStore.submitFieldData({
          group: 'registeredaddress',
          formFields: addressFields,
          send: false,
        })
        router.push({ name: 'RegisterBusinessDetails' })
      }
    }

    const onEnterManuallyClick = (analyticsName) => {
      analyticsStore.track({
        event: 'Business Search Completed',
        traits: {
          enterManually: true,
          enterManuallyLocation: analyticsName,
          searchDuration: '',
          includesAddress: false,
          referenceSource: 'XEMT Business',
          email: authStore.lastLogin,
        },
      })

      companyDetailsStore.companyDetails = {}
      router.push({ name: 'RegisterBusinessDetails' })
    }

    let lastSearch = ''
    let lastDuration = 0
    const onTextInput = async (query) => {
      query = query.trim()
      companySearchResultList.value = []
      noOptionsMessage.value = ''
      searchPerformed.value = false

      lastSearch = query
      if (query.length >= 4) {
        showLoadingSpinner.value = true
        try {
          const date = new Date()
          const result = await companyDetailsStore.getCompanyNameSearchResult({
            name: query,
            country: companyCountry.value,
          })

          switch (companyCountry.value) {
            case 'MY':
              result.filter((item) => (item.nationalId = item.nationalId[0] || ''))
              setOptionLabelSubtitle.value = 'nationalId'
              break
            case 'NZ':
              result.filter(
                (item) =>
                  (item.nationalId =
                    item.nationalId && item.nationalId.length > 0
                      ? item.nationalId.reduce((a, b) => (a.length <= b.length ? a : b))
                      : '')
              )
              setOptionLabelSubtitle.value = 'nationalId'
              break
            case 'US':
              setOptionLabelSubtitle.value = 'vatNumber'
          }

          if (lastSearch === query) {
            lastDuration = Math.floor(new Date().getTime() - date.getTime())
            companySearchResultList.value = result
            showLoadingSpinner.value = false
            searchPerformed.value = true
            if (companySearchResultList.value.length === 0) {
              noOptionsMessage.value = noOptionsMessageChoices[1]
            }
          }
        } catch (ex) {
          appStore.logException({
            text: 'Exception during searching for company',
            exception: ex,
          })
          if (lastSearch === query) {
            showLoadingSpinner.value = false
            noOptionsMessage.value = noOptionsMessageChoices[1]
            searchPerformed.value = true
          }
        }
      } else {
        noOptionsMessage.value = noOptionsMessageChoices[0]
        showLoadingSpinner.value = false
        searchPerformed.value = false
      }
    }

    const goBack = () => {
      router.replace({ name: 'RegisterBusinessInformation' })
    }

    return {
      companySearchResultList,
      noOptionsMessage,
      searchPerformed,
      setOptionLabelSubtitle,
      onEnterManuallyClick,
      onOptionSelected,
      onTextInput,
      showLoadingSpinner,
      goBack,
      isLoadingOptions,
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-enter-section {
  @apply flex justify-center;
  @apply text-base;

  .button {
    @apply ml-1;

    ::v-deep &.button--text {
      .button-inner {
        @apply p-0;
      }
    }

    &:focus,
    &:hover {
      @apply bg-transparent;
    }
  }
}

.custom-dropdown-footer {
  @apply px-4 py-2.5;
  @apply type-caption;
  @apply cursor-default;

  .custom-footer-button {
    @apply text-blue;
    @apply cursor-pointer;
  }
}
</style>

export default class Session {
  constructor(jwt) {
    this.isoCode = jwt.isoCode
    this.cultureCode = jwt.cultureCode
    // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // otherwise problem with notRegistered validation in email ?!?!?!?
    //this.locale = window.navigator.language
    this.locale = 'en-US'
  }
}

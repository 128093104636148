import defaultState from './state'

export default {
  setUser: (state, obj) => {
    state.user = obj
  },
  setToken: (state, obj) => {
    state.token = obj
  },
  setRefreshToken: (state, refreshToken) => {
    state.refreshToken = refreshToken
  },
  setLastLogin: (state, lastLogin) => {
    state.lastLogin = lastLogin
  },
  setProfileId: (state, profileId) => {
    state.profileId = profileId
  },
  setRefreshTimeout: (state, timeout) => {
    state.refreshTimeout = timeout
  },
  setRefreshRetries: (state, refreshRetries) => {
    state.refreshRetries = refreshRetries
  },
  setUserProfile: (state, profile) => {
    state.userProfile = profile
  },
  setTokenUrl: (state, tokenUrl) => {
    state.tokenUrl = tokenUrl
  },
  setLoginUrl: (state, loginUrl) => {
    state.loginUrl = loginUrl
  },
  setIsLiteRegistration: (state, val) => {
    state.isLiteRegistration = val
  },
  reset: (state) => {
    const keys = Object.keys(defaultState)
    for (const key of keys) {
      if (key !== 'lastLogin') {
        state[key] = defaultState[key]
      }
    }
  },
}

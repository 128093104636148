import getClient from '@/api/corporate/client/get'
import getStpEkycCheck from '@/api/corporate/client/stpEkycCheck/post'
import getStpApproval from '@/api/corporate/verify/stpApproveCorporate/post'
import getStpStartApproval from '@/api/corporate/verify/stpStartApproveCorporate/post'
import getStpGetApproval from '@/api/corporate/verify/stpGetApproveCorporate/post'
import getPurposeOfPayments from '@/api/corporate/purposeOfPayments/get'
import postCorporatePendingActions from '@/api/corporate/verify/corporatePendingActions/post'
import postFile from '@/api/corporate/verify/docs/post'
import getCorpProfile from '@/api/corporate/profiles/profile/get'
import putCorpProfile from '@/api/corporate/profiles/profile/put'
import additionalInformationMock from '@/data/pendingActions/additionalInformationMockData'
import companyTypeMapper from '@/composables/companyTypeMapper'
import config from '@/config/pendingActions'
import router from '@/router'
import mockedCompanyDetails from '@/data/pendingActions/companyDetails'
import { reactive } from '@vue/composition-api'
import { getRegionFromCountry } from '@/utils/i18n'
import { selectAddress, addressFormat } from '@/utils/address'

export default {
  setUploadedFile({ commit }, file) {
    commit('setUploadedFile', file)
  },
  setClearFile({ commit }, fileName) {
    commit('setClearFile', fileName)
  },
  setAdditionalInformation({ commit, getters }, additionalInformation) {
    if (getters.getAccountIsAllowedForStp) {
      // Passes the additionalInformation values on STP as is
      commit('setAdditionalInformation', additionalInformation)
    } else {
      // Ignores fields for old flow users
      const fieldsToIgnore = ['timeInIndustry']
      const fieldsToAdd = ['heardAboutUs', 'planToUseAccount']
      const isExtraInfoRequired = getters.getIsExtraInfoRequired

      let fields = additionalInformation.fields.filter(({ id }) => !fieldsToIgnore.includes(id))
      if (!isExtraInfoRequired) {
        fields = fields.concat(
          additionalInformationMock.fields.filter((item) => fieldsToAdd.includes(item.id))
        )
      }

      const value = {
        ...additionalInformation,
        fields: fields,
      }
      commit('setAdditionalInformation', value)
    }
  },
  setQuestionsAndAnswers({ commit }, questionsAndAnswers) {
    commit('setQuestionsAndAnswers', questionsAndAnswers)
  },
  initAdditionalInformationStatus({ commit, getters, rootGetters }, pendingActions) {
    let additionalInformationStatus = getters['getAdditionalInformationStatus']
    const corpProfile = getters['getCorpProfile']
    const country = corpProfile.registrationAddress.country.toUpperCase()
    const authorizedPersonnelTitle = rootGetters['cms/getCountrySpecificText'](
      'AuthorizedPersonnelTitle',
      country
    )

    const setHiddenValue = (statusName, group, additionalInformationStatus, pendingActions) => {
      const questionIndex = additionalInformationStatus.findIndex(
        (status) => status.name === statusName
      )

      additionalInformationStatus[questionIndex] = {
        ...additionalInformationStatus[questionIndex],
        ...(statusName === 'AuthorizedPersonnel' && { title: authorizedPersonnelTitle }),
        hidden: !pendingActions.data.kycRequirements.some((req) => req.group === group),
      }
    }

    setHiddenValue('AdditionalInfo', 'OtherInfo', additionalInformationStatus, pendingActions)
    setHiddenValue('AuthorizedPersonnel', 'Person', additionalInformationStatus, pendingActions)
    commit('setAdditionalInformationStatus', additionalInformationStatus)
  },
  setAdditionalInformationStatus({ commit, dispatch, getters }, section) {
    const additionalInformationStatus = getters['getAdditionalInformationStatus']

    // eslint-disable-next-line prettier/prettier
    if (section === 'questionsAndAnswers') {
      let answersCount = 0
      getters['getQuestionsAndAnswers'].forEach((qa) => {
        if (qa.value !== '') {
          answersCount++
        }
      })

      if (getters['getAdditionalInformation']?.fields.length === answersCount) {
        additionalInformationStatus[0].status = 'completed'

        // Set event traits dynamically based on question fetched by Corp BFF
        const mappedEventTraits = {
          destinationCountries: 'destinationOfFunds',
          generateBusiness: 'businessStrategy',
          businessDescription: 'businessDescription',
          purpose: 'purposeOfPayment',
          purposeOfAccount: 'purposeOfAccount',
        }

        const traits = reactive({})
        getters['getAdditionalInformation']?.fields.forEach((field) => {
          const key = mappedEventTraits[field.id]
          if (key) {
            traits[key] = getters['getQuestionsAndAnswersValueById'](field.id)?.toString()
          }
        })

        dispatch(
          'analytics/track',
          { event: 'Additional Information Saved', traits: traits },
          { root: true }
        )
      } else {
        additionalInformationStatus[0].status = 'pending'
      }
    } else if (section === 'authorizedPersonnel') {
      if (
        ((getters['getIsUsCorp'] || getters['getIsCaPrivate'] || getters['getIsGbSole']) &&
          getters['getHasNoOwners']) ||
        (getters['getHasOwnersOrAgreedNoOwners'] && getters['getIsUsLlcOrSp']) ||
        (getters['getHasOwnersOrAgreedNoOwners'] && getters['getIsDirectorAdded']) ||
        getters['getIsAdditionalInformationStatusCompleted'](section)
      ) {
        additionalInformationStatus[1].status = 'completed'
      } else {
        additionalInformationStatus[1].status = 'pending'
      }
    }

    commit('setAdditionalInformationStatus', additionalInformationStatus)
  },
  setDocumentaryEvidenceStatus({ commit }, documentaryEvidenceStatus) {
    commit('setDocumentaryEvidenceStatus', documentaryEvidenceStatus)
  },
  setAuthorizedPersonnel({ commit }, authorizedPersonnel) {
    commit('setAuthorizedPersonnel', authorizedPersonnel)
  },
  setAuthorizedPersonnelByIndex({ commit }, { authorizedPersonnel, index }) {
    commit('setAuthorizedPersonnelByIndex', { authorizedPersonnel, index })
  },
  setAllAuthorizedPersonnel({ commit }, authorizedPersonnelList) {
    commit('setAllAuthorizedPersonnel', authorizedPersonnelList)
  },
  setSignatoryData({ commit }, fields) {
    commit('setAuthorizedSignatoryDetails', fields)
  },
  setAgreeHasNoOwners({ commit }, status) {
    commit('setAgreeHasNoOwners', status)
  },
  deleteAuthorizedPersonnelByIndex({ commit }, { index }) {
    commit('deleteAuthorizedPersonnelByIndex', { index })
  },
  setIsFirstLoad({ commit }, value) {
    commit('setIsFirstLoad', value)
  },
  setDisplayHVModal({ commit }, value) {
    commit('setDisplayHVModal', value)
  },
  async getCorpUserDetails({ commit }) {
    const data = await getClient.exec()
    if (data) {
      commit('setCorpUserDetails', data)
    } else {
      throw new Error(`No CorpUserDetails saved in dynamoDb`)
    }
  },
  async getPurposeOfPayments({ commit }) {
    const data = await getPurposeOfPayments.exec()
    if (data) {
      commit('setPurposeOfPayments', data)
    } else {
      throw new Error(`No CorpUserDetails saved in dynamoDb`)
    }
  },
  async getCorpProfile({ commit }, { profileId }) {
    const data = await getCorpProfile.exec({ profileId })
    if (data) {
      commit('setCorpProfile', data)
    } else {
      throw new Error(`No corp user profile`)
    }
  },
  async putCorpProfile({ commit, getters, rootGetters }) {
    const corpProfile = getters['getCorpProfile']
    const companyDetails = getters['getCompanyDetails']
    const addressCompanyDetails = getters['getAddressCompanyDetails']
    const deviceCountry = rootGetters['device/getCountry']
    const dfxId = rootGetters['consent/getDfxIdFromCookie']
    const country = corpProfile?.registrationAddress?.country || ''
    const authorizedSignatory = getters['getFormAuthorizedSignatory']

    const userAddress = addressCompanyDetails?.registeredaddress?.companyAddress
    const profileAddress = corpProfile.registrationAddress
    const isFixedFormat = Boolean(corpProfile.registrationAddress?.fixedFormat)
    const isUserAddress = Boolean(userAddress)
    // use addressCompanyDetails.registeredaddress if provided (address field in company details page)
    // otherwise, fall back to corpProfile.registrationAddress
    const registrationAddressFreeFormat = selectAddress(
      isUserAddress,
      userAddress?.freeFormat,
      profileAddress?.freeFormat || {}
    )
    const registrationAddressFixedFormat = selectAddress(
      isUserAddress,
      userAddress?.fixedFormat,
      profileAddress?.fixedFormat || {}
    )
    const registrationAddress = addressFormat(
      country,
      isFixedFormat ? registrationAddressFixedFormat : registrationAddressFreeFormat, // data
      isFixedFormat
    )

    // use addressCompanyDetails.tradingaddress if provided (address field in company details page)
    // otherwise, fall back to corpProfile.tradingAddress if exists
    // otherwise, fall back to corpProfile.registrationAddress
    const userTradingAddress = addressCompanyDetails?.tradingaddress?.companyAddress
    const profileTradingAddress = corpProfile.tradingAddress
    const isUserTradingAddress = Boolean(userTradingAddress)

    const tradingAddressFreeFormat = selectAddress(
      isUserTradingAddress,
      userTradingAddress?.freeFormat,
      profileTradingAddress?.freeFormat || profileAddress?.freeFormat || {}
    )
    const tradingAddressFixedFormat = selectAddress(
      isUserTradingAddress,
      userTradingAddress?.fixedFormat,
      profileTradingAddress?.fixedFormat || profileAddress?.fixedFormat || {}
    )
    const tradingAddress = addressFormat(
      country,
      isFixedFormat ? tradingAddressFixedFormat : tradingAddressFreeFormat, // data
      isFixedFormat
    )

    const mainContactAddress = addressFormat(
      authorizedSignatory?.fixedFormatAddress?.countryCode ||
        authorizedSignatory?.freeFormatAddress?.countryCode ||
        '',
      isFixedFormat
        ? authorizedSignatory?.fixedFormatAddress
        : authorizedSignatory?.freeFormatAddress,
      isFixedFormat
    )

    var body = {
      CorporateAccountProfile: {
        ProfileId: corpProfile.profileId,
        ClientId: corpProfile.clientId,
        ClientNumber: corpProfile.clientNumber,
        BusinessUnitId: corpProfile.trmBusinessUnitId,
      },
      Region: getRegionFromCountry(country),
      CountryFromIpAtRegistration: deviceCountry,
      ProblematicCountryMismatchAtReg: country === deviceCountry,
      RegistrationAddress: registrationAddress,
      TradingAddress: tradingAddress,
      MainContact: {
        FirstName: corpProfile.mainContact.firstName,
        LastName: corpProfile.mainContact.lastName,
        Email: corpProfile.mainContact.email,
        DateOfBirth: authorizedSignatory?.dob || '',
        PhonePrefix: corpProfile.mainContact.phonePrefix,
        PhoneNumber: corpProfile.mainContact.phoneNumber,
        Address: mainContactAddress,
        IsSignatory: corpProfile.mainContact.isSignatory || false,
      },
      CompanyDetails: {
        RegisteredName: companyDetails.registeredname,
        NatureOfBusinessId: companyDetails.natureofbusiness,
        SubIndustryTypeId: companyDetails.industry,
        ExpectedAnnualTradingVolume: corpProfile.companyDetails.expectedAnnualTradingVolume || 0,
        IncorporationDate:
          companyDetails.incorporationdate || corpProfile.companyDetails.incorporationDate || '',
        Website: companyDetails.website || '',
      },
      dfxLeadSources: {
        dfxId: dfxId || '',
      },
    }
    const data = await putCorpProfile.exec({ profileId: corpProfile.profileId, fields: body })
    if (data) {
      commit('setCorpProfile', data)
    } else {
      throw new Error(`Could not update corp profile`)
    }
  },
  async getPendingActions({ commit }, req) {
    const data = await postCorporatePendingActions.exec(req)
    if (data) {
      commit('setPendingActions', data)
      return data.data
    } else {
      throw new Error(`No corporate pending actions, kyc`)
    }
  },
  async getBvdData({ commit, getters, dispatch }) {
    const bvdid = getters['getCorpProfile'].companyDetails.registrationNumber
    const companyCountry = getters['getCorpProfile'].registrationAddress.country
    const result = await dispatch(
      'corporateRegistration/companyDetails/getCompanyNameSearchResult',
      { name: bvdid, country: companyCountry },
      { root: true }
    )

    if (
      result &&
      result.length > 0 &&
      (result[0].id === bvdid || result[0].id === `${companyCountry}${bvdid}`)
    ) {
      commit('setBvdData', result[0])
      // Get business owner fields
      const { boFirstName, boLastName, boIsPep } = result[0]

      // Assuming BOs are listed in order
      const boList = boFirstName
        .map((firstName, index) => {
          return {
            firstName: firstName ?? '',
            lastName: boLastName[index] ?? '',
            dob: null,
            address: null,
            isUbo: true,
            personneltype: ['isUbo'],
            isPep: boIsPep[index],
          }
        })
        .filter((eachBo) => eachBo.firstName || eachBo.lastName)

      // Get directors fields
      const { cpyFirstName, cpyLastName } = result[0]
      // Assuming Directors are listed in order

      const directorList = cpyFirstName
        .map((firstName, index) => {
          return {
            firstName: firstName ?? '',
            lastName: cpyLastName[index] ?? '',
            dob: null,
            address: null,
            isDirector: true,
            personneltype: ['isDirector'],
          }
        })
        .filter((eachCpy) => eachCpy.firstName || eachCpy.lastName)

      commit('setBusinessOwnersFromBvd', boList)
      commit('setDirectorsFromBvd', directorList)
    }
  },
  async saveFile(
    { commit, dispatch },
    { docType, country, companyType, formData, file, goToPendingActions }
  ) {
    try {
      const req = {
        docType,
        country,
        companyType,
        formData,
      }

      const data = await postFile.exec(req)

      if (data) {
        if (goToPendingActions) {
          router.push({ name: 'PendingActions' })
        }
        commit('setSaveFile', { docType, s3key: data.key, file })
      }
    } catch (error) {
      dispatch('setClearFile', docType)
      router.push({
        name: 'PendingActionError',
        params: {
          errorType: 'dashboard',
        },
      })
      throw new Error(`unable to save file`)
    }
  },
  async allowedInDashboard({ dispatch, getters, rootGetters }) {
    let profileId = rootGetters['auth/getProfileId']
    let regionCompanyTypesMapping = new Map(
      getters['getPendingActionsConfig'].allowedForPendingActions
    )
    await dispatch('getCorpProfile', { profileId })
    const corpProfile = getters['getCorpProfile']
    const country = corpProfile.registrationAddress.country.toUpperCase()
    const companyTypeAcronym = corpProfile.companyDetails.companyTypeAcronym
    const companyType =
      companyTypeAcronym === 'NOT SUPPORTED'
        ? companyTypeMapper(corpProfile.companyDetails.companyType, country)
        : companyTypeAcronym

    const isAccountIsAllowedForStp = getters['getAccountIsAllowedForStp']

    // if not allowed region/companyType combo then redirect to fxweb with flag
    if (
      !regionCompanyTypesMapping.get(country)?.includes(companyType) &&
      !isAccountIsAllowedForStp
    ) {
      window.location.href = rootGetters['dashboard/getRedirectUrl']
      return false
    }

    try {
      await dispatch('getCorpUserDetails')
      await dispatch('getPendingActions', { country, companyType: companyType })
    } catch (ex) {
      router.push({
        name: 'PendingActionError',
        params: {
          isFullReload: true,
          errorType: 'dashboard',
        },
      })

      dispatch(
        'app/logException',
        {
          text: 'Verify exception',
          exception: ex,
        },
        { root: true }
      )
      return true
    }

    const corpUserDetails = getters['getCorpUserDetails']
    const pendingActions = getters['getPendingActions']
    if (
      pendingActions.data.verified.toLowerCase() === 'true' ||
      corpUserDetails?.approvalConditions?.approval
    ) {
      window.location.href = rootGetters['dashboard/getRedirectUrl']
      return false
    }

    // initialize status to hide non needed section
    dispatch('initAdditionalInformationStatus', pendingActions)

    // save submitted authorizedPersonnelList based on backend response
    const authorizedPersonnelList = corpUserDetails?.authorizedPersonnel || []
    if (authorizedPersonnelList.length > 0) {
      dispatch('setAllAuthorizedPersonnel', authorizedPersonnelList)
    }

    // save submitted questions and answers based on backend response
    const answers = corpUserDetails?.answers || []
    const isAnswersSavedInStore = getters['getQuestionsAndAnswers'].length > 0

    if (!isAnswersSavedInStore && answers.length > 0) {
      const payload = answers.map(({ questionID, questionText, answer }) => ({
        id: questionID,
        idText: questionText,
        value: questionID === 'destinationCountries' ? answer.split(',') : answer,
      }))
      dispatch('setQuestionsAndAnswers', payload)
    }

    // populate questionList
    const questionIdList = pendingActions?.data?.kycRequirements
      .filter((item) => item.group === 'OtherInfo')
      .map((item) => item.id)
    const mappedAdditionalInformation = {
      ...additionalInformationMock,
      fields: additionalInformationMock.fields.filter((item) => questionIdList.includes(item.id)),
    }
    await dispatch('getPurposeOfPayments')
    await dispatch('corporateRegistration/getAllCountryList', {}, { root: true })
    await dispatch('setAdditionalInformation', mappedAdditionalInformation)

    const documentIdList = pendingActions?.data?.kycRequirements
      .filter((item) => item.group === 'IdDoc')
      .map((item) => item.id)
    // uploadedFile state setup
    documentIdList.forEach((docId) => {
      dispatch('setUploadedFile', { target: docId, data: {} })
    })

    // this is the documentList we use as reference
    const documentaryEvidenceStatus = documentIdList.map((docId) => {
      const docNameMap = {
        idBankStatement: 'Bank Statement',
        bankStatement: 'Bank Statement',
        idArticleOfOrganization: 'Business Registration Document',
        utilityBill: 'Utility Bill',
        supplierInvoice: 'Invoice',
        idCustomerInvoice: 'Customer Invoice',
        idPassportOrDriversLicense: "Passport or Driver's License",
        idBusinessLicense: 'Business License',
        idPartnershipAgreement: 'Partnership Agreement',
        idOtherDocument: 'Other Documents',
      }
      const docName = docNameMap[docId]
      if (docName === undefined) {
        throw new Error('docId not supported')
      }

      // check status for uploadedFile in store
      const uploadedFile = getters['getUploadedFile']
      let status = 'pending'
      if (uploadedFile && Object.keys(uploadedFile).length > 0) {
        Object.entries(uploadedFile).forEach(([uploadedDocId, uploadedDoc]) => {
          if (uploadedDocId === docId) {
            status = uploadedDoc?.s3key ? 'completed' : 'pending'
          }
        })
      }
      return { name: docName, title: docName, status: status, id: docId }
    })

    await dispatch('setDocumentaryEvidenceStatus', documentaryEvidenceStatus)
    await dispatch('app/setIsDashboardLoaded', true, { root: true })
    return true
  },
  setPendingActionsConfig({ commit }) {
    commit('setPendingActionsConfig', config)
  },
  async setMockedCompanyDetails({ commit }) {
    commit('setMockedCompanyDetails', mockedCompanyDetails)
  },
  setStepperItems({ commit }, { id, items }) {
    commit('setStepperItems', { id, items })
  },
  setAllStepItems({ commit }, items) {
    commit('setAllStepItems', items)
  },
  setStepperValue(
    { commit, getters },
    { itemId, targetKey, value, customDescription, setChecked }
  ) {
    let items = getters.getStepperItems(targetKey)

    items = items.map((item) => {
      if (item.id === itemId) {
        item.activeItem = false
        if (customDescription) {
          item.description = customDescription
        } else if (item.id === 'destinationCountries') {
          let countryFullName = getters.getFundDestinationCountriesByIsoCode(value)
          item.description = countryFullName.join(', ')
        } else {
          item.description = value
        }
        item.isItemChecked = setChecked

        // Selects the next step to show
        let firstUndefined = items.find((x) => {
          return !x.isItemChecked && x.id !== 'registrationnumber'
        })

        if (firstUndefined) {
          firstUndefined.activeItem = true
        }
      }
      return item
    })
    commit('setStepperItems', { id: targetKey, items })
  },
  setStepperActive({ commit, getters }, { id, targetKey }) {
    let items = getters.getStepperItems(targetKey)
    const itemsToUpdate = items.map((item) => {
      return { ...item, activeItem: item.id === id }
    })
    commit('setStepperItems', { id: targetKey, items: itemsToUpdate })
  },
  setCompanyDetails({ commit }, items) {
    commit('setCompanyDetails', items)
  },
  setCompanyDetailsValue({ commit, getters }, { value, id }) {
    let items = getters.getCompanyDetails
    items[id] = value
    commit('setCompanyDetails', items)
  },
  setQuestionAndAnswerById({ commit }, questionAndAnswer) {
    commit('setQuestionAndAnswerById', questionAndAnswer)
  },
  setCurrentRoute({ commit }, routeName) {
    commit('setCurrentRoute', routeName)
  },
  setInitializeSteps({ commit, getters }) {
    try {
      let stepperItems = {}
      // Get values from store (company details, business purpose, additional documents)
      const getStepperPages = getters.getStpPages.filter((item) => item.isStepper)
      const additionalDocuments = getters.getDocumentaryEvidenceStatus.map(({ id, name }) => ({
        id,
        name,
      }))
      const additionalInfoIds = getters.getAdditionalInformation?.fields?.map((q) => q.id) || []
      getStepperPages.forEach((item, i) => {
        stepperItems[item.id] = getStepperPages[i].stepperOptions.items
        if (item.id === 'additionalDocuments') {
          stepperItems[item.id] = additionalDocuments
        } else if (item.id === 'businessPurpose') {
          // only render what backend returns
          stepperItems[item.id] = stepperItems[item.id].filter((bp) =>
            additionalInfoIds.includes(bp.id)
          )
        } else if (item.id === 'companyDetails') {
          const { regionName } = getters['getCorpProfile']
          // filter out region-specific questions
          // add allowed regions in PA.json
          stepperItems[item.id] = stepperItems[item.id].filter((cdItem) => {
            return 'allowedRegions' in cdItem ? cdItem.allowedRegions.includes(regionName) : true
          })
        }
        // Set active item if list is empty
        if (stepperItems[item.id].length > 0) {
          stepperItems[item.id][0].activeItem = true
        }
      })
      commit('setAllStepItems', stepperItems)
    } catch (error) {
      throw `Error loading stepper items: ${error}`
    }
  },
  setAddressCompanyDetails({ commit }, addressObject) {
    commit('setAddressCompanyDetails', addressObject)
  },
  async getStpEkycCheck(context, ekycCheckRequest) {
    const data = await getStpEkycCheck.exec(ekycCheckRequest)
    if (data) {
      return data
    } else {
      throw new Error(`StpEkycCheck Failed`)
    }
  },
  async getStpApproval(_context, stpApprovalRequest) {
    const data = await getStpApproval.exec(stpApprovalRequest)
    if (data) {
      return data
    } else {
      throw new Error(`StpApproval Failed`)
    }
  },
  async getStpStartApproval(_context, stpApprovalRequest) {
    const data = await getStpStartApproval.exec(stpApprovalRequest)
    if (data) {
      return data
    } else {
      throw new Error(`StpApproval Failed`)
    }
  },
  async getStpGetApproval(_context, stpApprovalRequest, complete = false) {
    const data = await getStpGetApproval.exec({ stpApprovalRequest, complete })
    if (data) {
      return data
    } else {
      throw new Error(`StpApproval Failed`)
    }
  },
  setIsStepperHidden({ commit }, isStepperHidden) {
    commit('setIsStepperHidden', isStepperHidden)
  },
  setClearSessionStore({ commit, dispatch }, isInitializingSteppers = true) {
    if (isInitializingSteppers) {
      dispatch('setInitializeSteps')
    }
    commit('setSessionStore')
  },
  setBusinessOwnersOptions({ commit }, options) {
    commit('setBusinessOwnersOptions', options)
  },
}

import { isEveryIndividualsValid } from '@/helpers/businessOwners'
import { getRegionFromCountry } from '@/utils/i18n'
import pendingActionsConfig from '@/config/pendingActions'

export default {
  getUploadedFile: (state) => {
    return state.uploadedFile
  },
  getCorpUserDetails(state) {
    if (state.corpUserDetails) {
      return state.corpUserDetails
    }
    return {}
  },
  getOtherDocumentRequestText(state) {
    if (state.corpUserDetails) {
      return state.corpUserDetails?.extraInfoRequired?.otherDocumentsText
    }
    return null
  },
  getOtherQuestionRequestText(state) {
    if (state.corpUserDetails) {
      return state.corpUserDetails?.extraInfoRequired?.otherQuestionsText
    }
    return null
  },
  getOtherDocumentDisplayableIds(state) {
    if (state.pendingActionsConfig) {
      return state.pendingActionsConfig.otherDocumentDisplayableIds
    }
    return []
  },
  getOtherQuestionDisplayableIds(state) {
    if (state.pendingActionsConfig) {
      return state.pendingActionsConfig?.otherQuestionDisplayableIds
    }
    return []
  },
  getCorpProfile(state) {
    if (state.corpProfile) {
      return state.corpProfile
    }
    return {}
  },
  getPendingActions(state) {
    if (state.pendingActions) {
      return state.pendingActions
    }
    return {}
  },
  getAdditionalInformation(state) {
    if (state.additionalInformation) {
      return state.additionalInformation
    }
    return {}
  },
  getQuestionsAndAnswers: (state) => {
    return state.questionsAndAnswers
  },
  getAdditionalInformationStatus: (state) => {
    return state.additionalInformationStatus
  },
  getCompanyDetailsCompletionStatus: (state, getters) => {
    if (getters.getIsExtraInfoRequired) {
      return true
    }
    const companyDetailsSteps = getters.getStepperItems('companyDetails')
    if (!companyDetailsSteps) {
      return false
    }
    const stepsCompleteness = companyDetailsSteps.map((step) => step.isItemChecked || false)
    return stepsCompleteness.every((x) => !!x)
  },
  getBusinessPurposesCompletionStatus: (state) => {
    const requiredPurposes = state.additionalInformation.fields || []
    const completedSoFar = state.questionsAndAnswers

    const mandatoryFields = requiredPurposes.filter((field) => field.mandatory)
    const allBusinessPurposesCompleted = mandatoryFields.every((requirement) => {
      const answerToReq = completedSoFar.find((ans) => ans.id === requirement.id)
      return answerToReq && answerToReq?.value
    })
    return allBusinessPurposesCompleted
  },
  getAdditionalDocumentsCompletionStatus: (state, getters) => {
    const uploadedFiles = getters.getUploadedFile
    // check if file is successfully uploaded by s3key
    return Object.values(uploadedFiles).every((doc) => !!doc.s3key && doc.s3key !== '')
  },
  getQuestionsAndAnswersValueById: (state) => (id) => {
    const persistedQuestionsAndAnswers = state.questionsAndAnswers
    if (persistedQuestionsAndAnswers.length > 0) {
      return persistedQuestionsAndAnswers.find((question) => question.id === id)?.value
    }
    return ''
  },
  getIsAdditionalInformationStatusCompleted: (_state, getters) => (section) => {
    if (section === 'questionsAndAnswers') {
      return getters.getQuestionsAndAnswers.length > 0
    } else if (section === 'authorizedPersonnel') {
      return getters.getHasOwnersOrAgreedNoOwners && getters.getNeedsDirectorOrDirectorIsAdded
    }
    return false
  },
  getPurposeOfPayments: (state) => {
    return state.purposeOfPayments
  },
  getDocumentaryEvidenceStatus(state) {
    if (state.documentaryEvidenceStatus) {
      return state.documentaryEvidenceStatus
    }
    return []
  },
  getIsOwnershipLimitExceeded(state) {
    const numberOfUbo = state.authorizedPersonnel.filter((item) => item.isUbo).length || 0
    return numberOfUbo >= 4
  },
  getAuthorizedPerssonelById: (state) => (id) => {
    const personnel = state.authorizedPersonnel.find((personnel) => personnel.id === id)
    return personnel
  },
  getAuthorizedPersonnel(state) {
    return state.authorizedPersonnel
  },
  getAuthorizedSignatory(state) {
    const { firstName, lastName, isSignatory, email } = state.corpProfile.mainContact
    return {
      firstName,
      lastName,
      isSignatory,
      email,
    }
  },
  getFormAuthorizedSignatory(state) {
    const authorizedSignatory = state.authorizedPersonnel.find((personnel) => personnel.isSignatory)
    return authorizedSignatory
  },
  getUboCount(state) {
    return state.authorizedPersonnel.filter((item) => item.isUbo || false).length
  },
  getHasNoOwners: (state) => {
    return state.agreeHasNoOwners
  },
  getHasOwnersOrAgreedNoOwners: (_state, getters) => {
    const result = getters.getHasNoOwners || getters.getIsUboAdded
    return result
  },
  getAuthorizedSignatoryDetails: (state) => {
    return state.authorizedSignatoryDetails
  },
  getIsFirstLoad: (state) => {
    return state.isFirstLoad
  },
  getDisplayHVModal: (state) => {
    return state.displayHVModal
  },
  getAgreeAllOwners: (state) => {
    return state.agreeAllOwners
  },
  getIsDirectorAdded(state) {
    return state.authorizedPersonnel.some((item) => item.isDirector)
  },
  getIsEveryIndividualsValid(state) {
    return isEveryIndividualsValid(state.authorizedPersonnel)
  },
  getIsUsLlcOrSp(state) {
    const allowedCompanyTypes = [1402, 1405]
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return allowedCompanyTypes.includes(companyTypeId)
  },
  getIsUsCorp(state) {
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return companyTypeId === 1401
  },
  getIsCaPrivate(state) {
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return companyTypeId === 1503
  },
  getIsCaSole(state) {
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return companyTypeId === 1506
  },
  getIsGbSole(state) {
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return companyTypeId === 1103
  },
  getIsGbLimited(state) {
    const companyTypeId = state.corpProfile?.companyDetails?.companyTypeId
    return companyTypeId === 1101
  },
  getShouldShowDirectorBanner(state, getters) {
    // LLC or SP always shows the banner
    if (getters.getIsUsLlcOrSp || getters.getIsCaSole) {
      // Shows the banner if has owner or a director
      return state.authorizedPersonnel.length > 0 && !getters.getIsDirectorAdded
    }
    // Corps we show the banner if only if the director was not added
    return !getters.getIsDirectorAdded
  },
  getNeedsDirector(state) {
    // referring to https://euronet.atlassian.net/wiki/spaces/XSS/pages/3004335455/Corp+Pending+Actions+Rules
    const companiesThatRequireDirector = [1401, 1402, 1503, 1101]
    return companiesThatRequireDirector.includes(state.corpProfile?.companyDetails?.companyTypeId)
  },
  // For ready to submit validation
  getNeedsDirectorOrDirectorIsAdded(_state, getters) {
    getters.getNeedsDirector || getters.getIsDirectorAdded
  },
  getIsUboAdded(state) {
    return state.authorizedPersonnel.some((item) => item.isUbo)
  },
  getIsExtraInfoRequired(state) {
    return state.pendingActions?.data?.isExtraInfoRequired
  },
  getRequiredStpPagesForExtraInfo(state, getters) {
    let result = new Set()

    if (!state.pendingActions?.data?.kycRequirements || !getters.getIsExtraInfoRequired) {
      return result
    }

    const kycRequirements = state.pendingActions?.data?.kycRequirements

    for (const requirement of kycRequirements) {
      if (requirement.group === 'OtherInfo') {
        result.add('businessPurpose')
      } else if (requirement.group === 'IdDoc') {
        result.add('additionalDocuments')
      }
    }
    return result
  },
  getRedirectUrlWithDashboardFlag(_state, _getters, _rootState, rootGetters) {
    const redirectUrl = rootGetters['corporateRegistration/getRedirectUrl']
    //set flag to true in order to ack not to redirect back
    const urlWithCompletedFlag = `${redirectUrl}?pendingActionsCompleted=true`

    return urlWithCompletedFlag
  },
  getPendingActionsConfig(state) {
    return state.pendingActionsConfig
  },
  getPendingActionsSubmitText(_state, getters, _rootState, rootGetters) {
    const corpProfile = getters.getCorpProfile
    const profileCountry = corpProfile.mainContact.address.country
    const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](profileCountry)
    const isAllowedCountry = countryConfig?.allowedSTPCountry || false

    if (isAllowedCountry) {
      const animationText =
        getters.getPendingActionsConfig.submit.animationText[
          getRegionFromCountry(profileCountry)
        ] || false
      return animationText ? animationText : []
    }
    return []
  },
  getAccountIsAllowedForStp(_state, getters, _rootState, rootGetters) {
    const corpProfile = getters.getCorpProfile
    // Check is profile is allow for STP
    if (corpProfile) {
      // Check country
      const profileCountry = corpProfile?.registrationAddress?.country
      const countryConfig = rootGetters['countries/getCountryConfigByCountryCode'](
        profileCountry || ''
      )
      if (countryConfig) {
        // Check company type
        const profileCompanyType = corpProfile.companyDetails.companyTypeId.toString()
        const isAllowedCompanyType =
          countryConfig?.allowedCompanyTypesForStp?.includes(profileCompanyType) || false

        // Return condition
        return (countryConfig?.allowedSTPCountry || false) && isAllowedCompanyType
      }
    }
    return false
  },
  getIsHighValueClient(_state, getters) {
    const corpProfile = getters.getCorpProfile
    if (corpProfile && corpProfile?.companyDetails) {
      const expectedAnnualAmount = corpProfile.companyDetails.expectedAnnualTradingVolume

      if (expectedAnnualAmount && expectedAnnualAmount >= 5*10e4) {
        return true
      }
    }

    return false;
  },
  getPhoneNumber(_state, getters) {
    const corpProfile = getters.getCorpProfile
    if (corpProfile) {
      return corpProfile.mainContact?.phoneNumber
    }

    return undefined;
  },
  getClientNumber(_state, getters) {
    const corpProfile = getters.getCorpProfile
    if (corpProfile) {
      return corpProfile.clientNumber
    }

    return undefined;
  },
  getTbuName(_state, getters) {
    const corpProfile = getters.getCorpProfile
    if (corpProfile) {
      return corpProfile.tbuName
    }

    return undefined;
  },
  getStpPageById: (_state, getters) => (key) => {
    const stpConfigPages = getters.getPendingActionsConfig.stp.pages
    return stpConfigPages.find((page) => page.id === key)
  },
  getMockedCompanyDetails: (state) => {
    if (state.mockedCompanyDetails) {
      return state.mockedCompanyDetails
    }
    return {}
  },
  getPendingListItems: (_state, getters) => {
    const requiredExtraInfoSet = getters.getRequiredStpPagesForExtraInfo
    const pendingActionsConfig = getters.getPendingActionsConfig
    let { pendingActionsList } = getters.getStpPageById('PendingActions').content

    if (!(requiredExtraInfoSet && pendingActionsConfig)) {
      return []
    }

    // case returned: display the extra questions/docs tabs only
    if (getters.getIsExtraInfoRequired) {
      return pendingActionsList.filter((item) => requiredExtraInfoSet.has(item.id))
    }

    // hide documents if not being asked
    if (getters.getDocumentaryEvidenceStatus.length === 0) {
      return pendingActionsList.filter((item) => item.id !== 'additionalDocuments')
    }

    // default: return the base list from BFF
    return pendingActionsList
  },
  getStepperItems: (state) => (group) => {
    return state.stepperItems[group]
  },
  getCompanyDetails: (state) => {
    return state.companyDetails
  },
  getFundDestinationCountriesByIsoCode: (state, getters, rootState, rootGetters) => (isoCodes) => {
    const allCountries = rootGetters['corporateRegistration/getAllCountryList']
    var allCountriesFullName = allCountries
      .filter((countryObj) => isoCodes.includes(countryObj.code))
      .map((c) => c.name)
    return allCountriesFullName
  },
  getCompanyInfoFields(_store, getters, _rootState, rootGetters) {
    const corpProfile = getters.getCorpProfile
    if (corpProfile) {
      const country = corpProfile?.registrationAddress?.country
      const getCompanyDetails = getters.getCompanyDetails
      const { registeredaddress, tradingaddress } = getters.getAddressCompanyDetails
      let registeredBusinessAddress = {
        street: [
          registeredaddress?.companyAddress?.freeFormat?.addressLine1,
          registeredaddress?.companyAddress?.freeFormat?.addressLine2,
          registeredaddress?.companyAddress?.freeFormat?.addressLine3,
        ]
          .filter(Boolean)
          .join(', '),
        city: registeredaddress?.companyAddress?.freeFormat?.place,
        state: registeredaddress?.companyAddress?.freeFormat?.county,
        postalCode: registeredaddress?.companyAddress?.freeFormat?.postalCode,
        country: country,
      }
      let operatingAddress = {
        street: [
          tradingaddress?.companyAddress?.freeFormat?.addressLine1,
          tradingaddress?.companyAddress?.freeFormat?.addressLine2,
          tradingaddress?.companyAddress?.freeFormat?.addressLine3,
        ]
          .filter(Boolean)
          .join(', '),
        city: tradingaddress?.companyAddress?.freeFormat?.place,
        state: tradingaddress?.companyAddress?.freeFormat?.county,
        postalCode: tradingaddress?.companyAddress?.freeFormat?.postalCode,
        country: country,
      }
      if (corpProfile.registrationAddress?.fixedFormat) {
        registeredBusinessAddress = {
          street: [
            registeredaddress?.companyAddress?.fixedFormat?.unit,
            registeredaddress?.companyAddress?.fixedFormat?.streetNumber,
            registeredaddress?.companyAddress?.fixedFormat?.streetName,
            registeredaddress?.companyAddress?.fixedFormat?.streetType,
          ]
            .filter(Boolean)
            .join(', '),
          city: registeredaddress?.companyAddress?.fixedFormat?.place,
          state: registeredaddress?.companyAddress?.fixedFormat?.county,
          postalCode: registeredaddress?.companyAddress?.fixedFormat?.postalCode,
          country: country,
        }
        operatingAddress = {
          street: [
            tradingaddress?.companyAddress?.fixedFormat?.unit,
            tradingaddress?.companyAddress?.fixedFormat?.streetNumber,
            tradingaddress?.companyAddress?.fixedFormat?.streetName,
            tradingaddress?.companyAddress?.fixedFormat?.streetType,
          ]
            .filter(Boolean)
            .join(', '),
          city: tradingaddress?.companyAddress?.fixedFormat?.place,
          state: tradingaddress?.companyAddress?.fixedFormat?.county,
          postalCode: tradingaddress?.companyAddress?.fixedFormat?.postalCode,
          country: country,
        }
      }
      const deviceCountry = rootGetters['device/getCountry']
      return {
        id: 'companyInformation',
        companyInformation: {
          FullLegalName: getCompanyDetails.registeredname,
          TradingName: getCompanyDetails.tradingname,
          CompanyType: getCompanyDetails.companytype,
          DateOfEstablishment: getCompanyDetails.incorporationdate,
          RegistrationNumber: getCompanyDetails.registrationnumber,
          VatNumber: getCompanyDetails.vatNumber || '',
          RegisteredBusinessAddress: registeredBusinessAddress,
          OperatingAddress: operatingAddress,
          OnlinePresence: getCompanyDetails.website || '',
          IpAddressCountry: deviceCountry,
        },
      }
    }
  },
  getAllPendingReadyToSubmit(_state, getters) {
    // Submission Status
    // 1. Company details
    const isCompanyDetailsReadyToSubmit = getters.getCompanyDetailsCompletionStatus
    // 2. Business Purposes
    const isBusinessPurposesReadyToSubmit = getters.getBusinessPurposesCompletionStatus
    // 3. Documents
    const isAdditionalDocumentsReadyToSubmit = getters.getAdditionalDocumentsCompletionStatus
    // 4. Business Owners
    const isBusinessOwnersReadyToSubmit = getters.getIsDirectorAdded

    return (
      isCompanyDetailsReadyToSubmit &&
      isBusinessPurposesReadyToSubmit &&
      isAdditionalDocumentsReadyToSubmit &&
      isBusinessOwnersReadyToSubmit
    )
  },
  getIsStepperHidden(state) {
    return state.isStepperHidden
  },
  getCurrentRoute: (state) => {
    return state.currentRoute
  },
  getCurrentStepperItems: (state) => {
    return state.stepperItems[state.currentRoute]
  },
  getActiveStepperItem: (_state, getters) => {
    return getters.getCurrentStepperItems?.find((item) => item.activeItem) || null
  },
  getStepperOptions: (_state, getters) => {
    const currentRoute = getters.getCurrentRoute
    const pageConfig = getters.getStpPageById(currentRoute)
    if (pageConfig?.stepperOptions) {
      return pageConfig.stepperOptions
    }
    return {}
  },
  getStepperOptionsHiddenFlag: (_state, getters) => {
    const currentRoute = getters.getCurrentRoute
    const pageConfig = getters.getStpPageById(currentRoute)
    return !!pageConfig?.stepperOptions?.isHidden
  },
  getStpPages: () => {
    return pendingActionsConfig.stp.pages
  },
  getStepperInitializeState: (state) => {
    return Object.keys(state.stepperItems).length === 0
  },
  getIsStepperActivated: (_state, getters) => {
    const currentRoute = getters.getCurrentRoute
    const pageConfig = getters.getStpPageById(currentRoute)
    if (pageConfig) {
      return pageConfig.isStepper
    }
    return false
  },
  getAddressCompanyDetails: (state) => {
    return state.addressCompanyDetails
  },
  getStepperIsCompleted: (state) => (group) => {
    if (state.stepperItems[group]) {
      return state.stepperItems[group]
    }
  },
  getBusinessOwnersFromBvd: (state) => {
    return state.businessOwnersFromBvd
  },
  getDirectorsFromBvd: (state) => {
    return state.directorsFromBvd
  },
  getAuthorizedPersonnelFromBvd: (state) => {
    return state.businessOwnersFromBvd.concat(state.directorsFromBvd)
  },
  getBusinessCentralUrl: (_state, _getters, _rootState, rootGetters) => {
    // Get DFX cookie
    const dfxId = rootGetters['consent/getDfxIdFromCookie']
    // Get Api user
    const isApiUser = rootGetters['dashboard/getCorpProfile']?.isApiUser
    // Return business central URL
    return dfxId || isApiUser ? config.VUE_APP_URL_BUSINESS_CENTRAL : false
    // TODO: Please add Pinia code in migration branch
    /*     const getRedirectUrl = () => {
      // Get DFX cookie
      const dfxId = useConsentStore.getDfxIdFromCookie
      // Get Api user
      const isApiUser = useDashboardStore.getCorpProfile
      // Return business central URL
      return dfxId || isApiUser ? config.VUE_APP_URL_BUSINESS_CENTRAL : false
    } */
  },
  getRedirectUrl: (_state, getters) => {
    // Return right redirection URL (FXWeb/Galileo or (DFX Id/API user))
    return getters.getRedirectUrlWithDashboardFlag || getters.getBusinessCentralUrl
    // TODO: Please add Pinia code in migration branch
    //const getRedirectUrl = () => (redirectionUrl || businessCentralUrl)
  },
  getBusinessOwnersOptions: (state) => {
    return state.businessOwnersOptions
  },
}

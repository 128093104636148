/* eslint-disable */ // Remove after clean linter warnings
export default {
  setActiveStepIdx(state, idx) {
    state.activeStepIdx = idx
  },
  setActiveStepPageTitle(state, pageTitle) {
    if(state.activeStepIdx && state.steps[state.activeStepIdx])
      state.steps[state.activeStepIdx].pageTitle = pageTitle
  },
  setCountryList(state, countryList) {
    state.countryList = countryList
    state.supportCountryList = countryList.filter(country => country.canRegister)
  },
  setAllCountryList(state, allCountryList) {
    state.allCountryList = allCountryList
  },
  setStateList(state, stateList) {
    state.stateList = stateList
  },
  setStreetTypeList(state, streetTypeList) {
    state.streetTypeList = streetTypeList
  },
  setNatureOfBusinessList(state, natureOfBusinessList) {
    state.natureOfBusinessList = natureOfBusinessList
  },
  setSubIndustryList(state, subIndustryList) {
    state.subIndustryList = subIndustryList
  },
  setPhoneDetails(state, phoneDetails) {
    state.phoneDetails = phoneDetails
  },
  setBusinessDetail(state, businessDetail) {
    state.businessDetail = businessDetail
  },
  setPersonalAddress(state, personalAddress) {
    state.personalAddress = personalAddress
  },
  setPhoneNumber(state, phoneNumber) {
    if (state.phoneDetails) {
      state.phoneDetails.number = phoneNumber
    }
  },
  setSteps(state, steps) {
    state.steps = steps
  },
  setNewCustomerRequiredFields(state, payload) {
    state.newCustomerRequiredFields = payload
  },
  setClearCustomerFieldsGroup(state, group) {
    delete state.corporateForm[group]
  },
  setMockedCustomerRequiredFields(state, payload) {
    state.mockedCustomerRequiredFields = payload
  },
  setCorporateFormFieldValue(state, payload) {
    const { name, formData, clearFieldValues } = payload
    if (clearFieldValues) state.corporateForm = {}
    let form = {}
    for (let i in formData) {
      const data = formData[i]
      form[data.id] = {
        name: data.label,
        value: data.value,
      }
    }
    state.corporateForm[name] = { ...state.corporateForm[name], ...form }
  },
  setCorporateForm(state, corporateForm) {
    state.corporateForm = corporateForm
  },
  setUserId(state, userId) {
    state.userId = userId
  },
  setBusinessSameOperatingAddress(state, businessSameOperatingAddress) {
    state.businessSameOperatingAddress = businessSameOperatingAddress
  },
  setCompanyTypeName(state, companyTypeName) {
    state.companyTypeName = companyTypeName
  },
  resetForm(state) {
    state.phoneDetails.number = ''
    state.phoneDetails.areaCode = ''
    state.phoneDetails.nameAbbr = ''

    state.businessDetail.companyName = ''
    state.businessDetail.businessDifferentName = false
    state.businessDetail.ein =''
    state.businessDetail.website = ''
    state.businessDetail.natureOfBusiness = ''
    state.businessDetail.signinAuthority = false
    state.businessDetail.tradingName = ''

    state.personalAddress.id = ''
    state.personalAddress.language = ''
    state.personalAddress.buildingName = ''
    state.personalAddress.buildingNumber = ''
    state.personalAddress.subBuilding = ''
    state.personalAddress.addressLine1 = ''
    state.personalAddress.addressLine2 = ''
    state.personalAddress.addressLine3 = ''
    state.personalAddress.street = ''
    state.personalAddress.city = ''
    state.personalAddress.district = ''
    state.personalAddress.countryCode = ''
    state.personalAddress.postCode = ''
    state.personalAddress.provinceName = ''

    state.personalDetail.firstName = ''
    state.personalDetail.lastName = ''
    state.personalDetail.dateOfBirth = ''

    state.saveFormFields = []
    state.businessSameOperatingAddress = false
    state.corporateForm = {}
    state.userId = ''
    state.clientNumber = ''
  },
  setMarketingConsentPopup (state, promptForMarketingConsent) {
    state.promptForMarketingConsent = promptForMarketingConsent
  },
  setClientNumber(state, clientNumber) {
    state.clientNumber = clientNumber
  },
  setCurrencyTypeList(state, currencyTypeList) {
    state.currencyTypeList = currencyTypeList
  },
  setMarketingOptIn(state, marketingOptIn) {
    state.marketingOptIn = marketingOptIn
  },
  setSaveFormFields(state, formData) {
    // Save object checking for duplicates
    const existingIndex = state.saveFormFields.findIndex(item => item.id === formData.id)
    if (existingIndex !== -1) {
      // If duplicate is found, replace it with the new object
      state.saveFormFields[existingIndex] = formData;
    } else {
      // no duplicates, push the object into the array
      state.saveFormFields.push(formData);
    }
  }
}
/* eslint-disable */ // Remove after clean linter warnings

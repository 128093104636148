import Cookies from 'js-cookie'

export default {
  getLastLogin(state) {
    if (state.lastLogin) {
      return state.lastLogin
    }
    return ''
  },
  getSsoIdLaunchpad() {
    return Cookies.get('xe_sso_id_launchpad')
  },
  getProfileId(state) {
    return state.profileId
  },
  getUserProfile(state) {
    if (state.userProfile) {
      return state.userProfile
    }
    return {}
  },
  getRefreshTimeout(state) {
    return state.refreshTimeout
  },
  getRefreshRetries(state) {
    return state.refreshRetries
  },
  getUserId(state) {
    if (state.user) {
      return state.user.id
    }
    return ''
  },
  getLoginUrl(state) {
    return state.loginUrl
  },
  getTokenUrl(state) {
    return state.tokenUrl
  },
  getToken(state) {
    return state.token
  },
}

export default {
  setHasMessage: (state, hasMessage) => {
    state.hasMessage = hasMessage
  },
  setXeVersion: (state, version) => {
    state.version = version
  },
  setLoadedState: (state, loadedState) => {
    state.loaded = loadedState
  },
  setIsDashboardLoaded: (state, isDashboardLoaded) => {
    state.isDashboardLoaded = isDashboardLoaded
  },
}

import LogRocket from 'logrocket'
import { logrocketSetting } from '@/composables/useSession'

export default {
  setSessionId({ commit }, sessionId) {
    commit('setSessionId', sessionId)
  },
  initLogRocket({ dispatch, commit }) {
    let settings
    // Set options based on the environment
    if (config.VUE_APP_ENVIRONMENT === 'integration') {
      // Development env settings
      settings = logrocketSetting({
        // DOM video recording - https://docs.logrocket.com/v1.0/reference/dom
        dom: {
          isEnabled: true, //disable|enable all DOM recording
          inputSanitizer: false, // obfuscate all user-input elements <select> and <input>
        },
        shouldCaptureIP: false,
      })
    } else {
      // Staging, prod env settings
      settings = logrocketSetting(
        {
          dom: {
            isEnabled: true, //disable|enable all DOM recording
            inputSanitizer: true, // obfuscate all user-input elements <select> and <input>
          },
          shouldCaptureIP: false,
        },
        true // Set network sanitize for logRocket
      )
    }

    // Log Rocket init - // https://docs.logrocket.com/v1.0/reference/init
    LogRocket.init(config.VUE_APP_KEYS_LOGROCKET_KEY, settings)
    // Session URL Handler
    LogRocket.getSessionURL((sessionUrl) => {
      dispatch(
        'analytics/track',
        {
          event: 'LogRocket',
          traits: {
            sessionURL: sessionUrl,
          },
        },
        { root: true }
      )
      commit('setLogRocketSessionUrl', sessionUrl)
    })
  },
}

import defaultState from './defaultState.json'
export default {
  setUploadedFile(state, { target, data }) {
    const clonedUploadedFile = { ...state.uploadedFile }
    state.uploadedFile = {
      ...clonedUploadedFile,
      [target]: { ...clonedUploadedFile[target], ...data },
    }
  },
  setClearFile(state, fileName) {
    state.uploadedFile[fileName] = {}
  },
  setSaveFile(state, { docType, s3key, file }) {
    state.uploadedFile[docType] = { ...file, s3key }
  },
  setCorpUserDetails: (state, corpUserDetails) => {
    state.corpUserDetails = corpUserDetails
  },
  setCorpProfile: (state, corpProfile) => {
    state.corpProfile = corpProfile
  },
  setPendingActions: (state, pendingActions) => {
    state.pendingActions = pendingActions
  },
  setAdditionalInformation: (state, additionalInformation) => {
    state.additionalInformation = additionalInformation
  },
  setQuestionsAndAnswers: (state, questionsAndAnswers) => {
    state.questionsAndAnswers = questionsAndAnswers
  },
  setAdditionalInformationStatus: (state, additionalInformationStatus) => {
    state.additionalInformationStatus = additionalInformationStatus
  },
  setPurposeOfPayments: (state, purposeOfPayments) => {
    state.purposeOfPayments = purposeOfPayments
  },
  setDocumentaryEvidenceStatus: (state, documentaryEvidenceStatus) => {
    state.documentaryEvidenceStatus = documentaryEvidenceStatus
  },
  setAuthorizedPersonnel(state, authorizedPersonnel) {
    const selectedPersonnel = state.authorizedPersonnel
    state.authorizedPersonnel = [...selectedPersonnel, authorizedPersonnel]
  },
  setAuthorizedPersonnelByIndex(state, { authorizedPersonnel, index }) {
    const authorizedPersonnelList = [...state.authorizedPersonnel]
    authorizedPersonnelList.splice(index, 1, authorizedPersonnel)
    state.authorizedPersonnel = [...authorizedPersonnelList]
  },
  setAllAuthorizedPersonnel(state, authorizedPersonnelList) {
    state.authorizedPersonnel = authorizedPersonnelList
  },
  setAuthorizedSignatoryDetails(state, formData) {
    state.authorizedSignatoryDetails = formData
  },
  deleteAuthorizedPersonnelByIndex(state, { index }) {
    const authorizedPersonnelList = [...state.authorizedPersonnel]
    authorizedPersonnelList.splice(index, 1)
    state.authorizedPersonnel = [...authorizedPersonnelList]

    if (state.authorizedPersonnel.length === 0) {
      state.additionalInformationStatus[1].status = 'pending'
      state.agreeHasNoOwners = false
    }
  },
  setIsFirstLoad(state, value) {
    state.isFirstLoad = value
  },
  setDisplayHVModal(state, value) {
    state.displayHVModal = value
  },
  setAgreeHasNoOwners(state, status) {
    state.agreeHasNoOwners = status
  },
  setPendingActionsConfig(state, status) {
    state.pendingActionsConfig = status
  },
  setMockedCompanyDetails(state, payload) {
    state.mockedCompanyDetails = payload
  },
  setStepperItems: (state, { id, items }) => {
    const stepperItems = { ...state.stepperItems }
    stepperItems[id] = items
    state.stepperItems = stepperItems
  },
  setAllStepItems: (state, items) => {
    state.stepperItems = items
  },
  setCompanyDetails: (state, items) => {
    state.companyDetails = items
  },
  setQuestionAndAnswerById: (state, questionAndAnswer) => {
    let targetId
    // Check for duplicates
    const existingObj = state.questionsAndAnswers.find((item) => {
      targetId = questionAndAnswer.id
      return item.idText === questionAndAnswer.idText
    })
    // If it's not a duplicate, add it; if it's a duplicate, update it
    if (!existingObj) {
      state.questionsAndAnswers.push(questionAndAnswer)
    } else {
      // Update the existing object
      state.questionsAndAnswers = state.questionsAndAnswers.map((item) => {
        if (item.id === targetId) {
          item.value = questionAndAnswer.value
        }
        return item
      })
    }
  },
  setAddressCompanyDetails: (state, addressObject) => {
    state.addressCompanyDetails = addressObject
  },
  setCurrentRoute: (state, routeName) => {
    state.currentRoute = routeName
  },
  setIsStepperHidden: (state, isStepperHidden) => {
    state.isStepperHidden = isStepperHidden
  },
  setBvdData: (state, data) => {
    state.bvdData = data
  },
  setBusinessOwnersFromBvd: (state, item) => {
    state.businessOwnersFromBvd = item
  },
  setDirectorsFromBvd: (state, item) => {
    state.directorsFromBvd = item
  },
  setSessionStore: (state) => {
    // must use Object.assign() to not lose observers
    Object.assign(state, defaultState)
  },
  setBusinessOwnersOptions: (state, options) => {
    state.businessOwnersOptions = options
  },
}

import store from '@/store'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'

const setDataFields = async (addressObject) => {
  let { addressLine1, unit, city, state } = addressObject
  const corporateRegistrationStore = useCorporateRegistrationStore()

  // Case 1
  if (!!addressLine1 && addressLine1.indexOf('Unit') !== -1) {
    unit = addressLine1.match(/Unit \d+\W*\d+\W*/)[0]
    addressLine1 = addressLine1.replace(unit, '')
    unit = unit.match(/\d+\W*\d+/)[0]
  }
  // Case 2
  let states = corporateRegistrationStore.stateList
  city = city.replace(/Wp\s/, '')

  if (city) {
    let stateAttr = city.split(', ')
    const getState = (state) => corporateRegistrationStore.mapStateValue(state)

    if (states.some((e) => e.name === stateAttr[1])) {
      state = await getState(stateAttr[1])
      city = stateAttr[0]
    } else if (states.some((e) => e.name === stateAttr[0])) {
      state = await getState(stateAttr[0])
      city = ''
    }

    if (stateAttr.length === 1) {
      city = stateAttr[0]
    }
  }
  return {
    ...addressObject,
    addressLine1,
    unit,
    city,
    state,
  }
}
export const fixStreetAddressMY = async (addressObject) => await setDataFields(addressObject)

import Cookies from 'js-cookie'
import { clientAffiliateCookie, dfxCookie } from '@/composables/useConsentManager/useConsent'

export default {
  getLeadSourcesState(state) {
    if (state.leadSourcesState) {
      return state.leadSourcesState
    }
    return {}
  },
  getConsentState(state) {
    return state.consentState
  },
  getDfxIdFromCookie() {
    const parsedDfxCookie = new URLSearchParams(Cookies.get(dfxCookie))
    const dfxId = parsedDfxCookie.get('_dfxid')
    return dfxId
  },
  getClientAffiliateIdFromCookie() {
    const parsedClientAffiliate = new URLSearchParams(Cookies.get(clientAffiliateCookie))
    const clientAffiliate = parsedClientAffiliate.get('_clientagentid')
    return clientAffiliate
  },
}

export default {
  companyCountry: '',
  companyType: '',
  companyDetails: {},
  // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  companyAddress: {
    id: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    countryCode: '',
    postCode: '',
    state: '',
    county: '',
    consolidationCode: '',
    naceCode: '',
    naceLabel: '',
    incorporationDate: '',
    nationalId: [],
    vatNumber: [],
    website: []
  },
  companyOperatingAddress: {
    id: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    countryCode: '',
    postCode: '',
    state: '',
    county: '',
    consolidationCode: '',
    naceCode: '',
    naceLabel: '',
    incorporationDate: '',
    nationalId: [],
    vatNumber: [],
    website: []
  },
  companyTypeList: [],
  signingAuthority: false,
  hasCompanyTypeChanged: false,
}

import getUserCountry from '@/api/corporate/countries/userCountry/get'

export default {
  async checkDeviceCountry({
    dispatch,
    commit
  }) {
    try {
      const { data } = await getUserCountry.exec()
      commit('device/setCountry', data, { root: true })
      commit('corporateRegistration/companyDetails/setCompanyCountry', data, { root: true })
    } catch (e) {
      dispatch('app/logException', {
        text: 'Exception during getting user country',
        exception: ex,
      }, {
        root: true
      })
    }
  }
}

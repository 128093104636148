export default {
  token: null,
  user: null,
  lastLogin: null,
  profileId: null,
  refreshTimeout: null,
  refreshRetries: 0,
  userProfile: {},
  loginUrl: null,
  tokenUrl: null,
  refreshToken: null,
}

export default {
  setCountryId: (state, id) => {
    state.countryId = id
  },
  setCountry: (state, id) => {
    state.country = id
  },
  setRegistrationIdForUser: (state, { user, id }) => {
    state.corporateRegistrationIds[user] = id
  }
}

import * as randomBytes from 'randombytes'
import * as md5 from 'md5'

export default {
  countryId: 105, // TODO not needed ?
  country: 'GB',
  id: md5(`RMTWeb-${new Date().toISOString()}-${randomBytes(16)}`),
  token: null,
  corporateRegistrationIds: {}
}

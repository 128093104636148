import { fixStreetAddress } from '@/composables/useBVDAddressFormatter'
import { extractStreetNameAndStreetType } from '@/composables/useAddress'
import { useAnalyticsStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { getMappedCountry } from '@/utils/i18n'

/*
 * Editable dropdown sends input events for every text typed and option selected.
 * So this function will check from the search result list to see if it was selected.
 */
export const onOptionSelected = async (
  query,
  companySearchResultList,
  routeTo,
  router,
  store,
  lastDuration
) => {
  const authStore = useAuthStore()
  const companyDetailsStore = useCompanyDetailsStore()
  const corporateRegistrationStore = useCorporateRegistrationStore()

  // Set and save company data
  const selectedCompany = companySearchResultList.find((company) => company.id === query)
  const companyCountry = companyDetailsStore.companyCountry

  const removeCountryPrefix = (bvdId) => {
    if (bvdId.substring(0, 2) === companyCountry) {
      bvdId = bvdId.substring(2)
    }
    return bvdId
  }

  if (selectedCompany) {
    selectedCompany.id = removeCountryPrefix(selectedCompany.id)
    // Set company object
    companyDetailsStore.companyDetails = selectedCompany
    let taxNumber = selectedCompany.vatNumber
    let oldRegNumber = ''
    if (selectedCompany.id) {
      oldRegNumber = selectedCompany.nationalId
    }
    const companyFields = [
      {
        id: 'registeredname',
        value: selectedCompany.companyName,
      },
      {
        id: 'ustaxnumber',
        value: taxNumber,
      },
      {
        id: 'registrationnumber',
        value: selectedCompany.countryCode === 'MY' ? oldRegNumber : selectedCompany.id,
      },
      {
        id: 'website',
        value:
          selectedCompany.website && selectedCompany.website.length > 0
            ? selectedCompany.website[0]
            : null,
      },
      {
        id: 'incorporationdate',
        value: selectedCompany.incorporationDate,
      },
    ]
    // Set address object
    const addressObject = await fixStreetAddress(getMappedCountry(companyCountry), selectedCompany)
    // set state to null if not mapped correctly
    const stateLength = addressObject.state ? addressObject.state.length : 0
    if (addressObject.state) {
      addressObject.state = stateLength <= 3 ? addressObject.state : selectedCompany.county || ''
    }
    const [streetName, streetType] = extractStreetNameAndStreetType(addressObject.streetname)
    const addressFields = [
      // free-format address
      { id: 'line1', value: addressObject.addressLine1 },
      { id: 'line2', value: addressObject.addressLine2 },
      { id: 'line3', value: addressObject.addressLine3 },
      // fixed-format address
      { id: 'streetname', value: streetName },
      { id: 'streetnumber', value: addressObject.streetnumber },
      { id: 'streettype', value: streetType },
      { id: 'unit', value: addressObject.unit },
      { id: 'area', value: addressObject?.area },
      // shared
      { id: 'city', value: addressObject.city },
      { id: 'state', value: addressObject.state },
      { id: 'postalcode', value: addressObject.postCode },
      { id: 'country', value: companyCountry },
    ]
    // Fire search completed event
    trackEvent('Business Search Completed', {
      enterManually: false,
      enterManuallyLocation: '',
      searchDuration: lastDuration,
      includesAddress:
        Object.prototype.hasOwnProperty.call(selectedCompany, 'addressLine1') ||
        Object.prototype.hasOwnProperty.call(selectedCompany, 'city') ||
        Object.prototype.hasOwnProperty.call(selectedCompany, 'postCode'),
      referenceSource: 'XEMT Business',
      email: authStore.lastLogin,
    })

    // Send company fields to corp-bff
    const companyDataResponse = await corporateRegistrationStore.submitFieldData({
      group: 'company',
      formFields: companyFields,
    })
    // Check if registration number is duplicated
    if (companyDataResponse && companyDataResponse.length > 0) {
      // Get duplicate company
      const duplicateCompany = companyDataResponse.find(
        (error) =>
          error.id === 'registrationnumber' &&
          error.errors.find((suberror) => suberror.code === 'DUPCOMPANY_005')
      )
      // If duplicated route user to user reg
      if (duplicateCompany) {
        router.push({
          name: 'RegisterError',
          params: {
            errorType: 'duplicate',
          },
        })
        return
      }
    }
    await corporateRegistrationStore.saveFormFields({
      group: 'company',
      formFields: companyFields,
    })
    // Save company address
    await corporateRegistrationStore.submitFieldData({
      group: 'registeredaddress',
      formFields: addressFields,
      send: false,
    })
    await corporateRegistrationStore.saveFormFields({
      group: 'registeredaddress',
      formFields: addressFields,
    })
    // Push to given route
    router.push(routeTo)
  }
}

export const onEnterManuallyClick = (analyticsName, routeTo, router) => {
  const authStore = useAuthStore()
  const companyDetailsStore = useCompanyDetailsStore()
  const analyticsStore = useAnalyticsStore()

  if (analyticsName) {
    analyticsStore.track({
      event: 'Business Search Completed',
      traits: {
        enterManually: true,
        enterManuallyLocation: analyticsName,
        searchDuration: '',
        includesAddress: false,
        referenceSource: 'XEMT Business',
        email: authStore.lastLogin,
      },
    })
  }

  companyDetailsStore.companyDetails = {}
  router.push(routeTo)
}

export const setOptionLabelSubtitle = (companyCountry, result) => {
  switch (companyCountry) {
    case 'MY':
      result.filter((item) => (item.nationalId = item.nationalId[0] || ''))
      return 'nationalId'
    case 'NZ':
      result.filter(
        (item) =>
          (item.nationalId =
            item.nationalId && item.nationalId.length > 0
              ? item.nationalId.reduce((a, b) => (a.length <= b.length ? a : b))
              : '')
      )
      return 'nationalId'
    case 'US':
      result.filter((item) => (item.vatNumber = item.vatNumber[0] || ''))
      return 'vatNumber'
  }
}
const trackEvent = (eventName, traits) => {
  const analyticsStore = useAnalyticsStore()
  analyticsStore.track({
    event: eventName,
    traits,
  })
}

export default {
  getCountry: (state) => {
    return state.country
  },  
  getCorporateRegistrationIdForUser: (state) => (user) => {
    if (state.corporateRegistrationIds[user])
      return state.corporateRegistrationIds[user]
    return null
  }
}

export default {
  async setConsent({ commit }, consent) {
    commit('setConsentState', consent)
  },
  async setLeadSources({ commit }, dfxLeadSources) {
    commit('setLeadSourcesState', dfxLeadSources)
  },
  setAcquisitionSourceEvent({ dispatch, getters, rootGetters }) {
    const leadSourcesState = getters.getLeadSourcesState
    const profileId = rootGetters['auth/getProfileId']
    let eventObject = {
      userId: profileId,
      traits: {
        acquisitionSource: '',
      },
    }

    if (Object.keys(leadSourcesState).length > 0) {
      eventObject.traits.acquisitionSource = leadSourcesState?.dfxId
        ? leadSourcesState.dfxId
        : `aff-${leadSourcesState?.clientAffiliate}`
      dispatch('analytics/identify', eventObject, { root: true })
      return
    }
  },
}

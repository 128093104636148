import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
// import api from './api' // Placeholder until we split modules out correctly

Vue.use(Vuex)

const localStorage = createPersistedState({
  paths: ['device', 'auth', 'dashboard', 'consent'],
})

const sessionStorage = createPersistedState({
  paths: [
    'countries',
    'session',
    'OTP.counters',
    'corporateRegistration',
    'corporateRegistration/companyDetails',
    'registration',
  ],
  storage: window.sessionStorage,
})

function loadModules() {
  const context = require.context('@/store', true, /index\.js/)
  const modules = context
    .keys()
    .map((filename) => {
      if (filename === './index.js') {
        return
      } // ignore store/index.js (this file)
      return {
        name: filename.replace(/\/index\.js/, '').replace('./', ''), // give module name from directory
        module: context(filename).default, // resolve module and return default
      }
    })
    .filter((module) => !!module) // Remove all undefined items
  return modules
}

const modules = {}
loadModules().forEach((item) => {
  modules[item.name] = item.module
})

export default new Vuex.Store({
  modules,
  plugins: [localStorage, sessionStorage],
})

export default {
  setCountries: (state, arr) => {
    state.countries = arr
  },
  setCountrySettings: (state, arr) => {
    state.countrySettings = arr
  },
  setAgentSettings: (state, arr) => {
    state.agentSettings = arr
  },
}

import getSystemFields from '@/api/launchpad/system/fields/_country/get'

export default {
  async getSystemFields({ commit }, country) {
    const { data } = await getSystemFields.exec(country)
    commit('setSystemFields', data)
    commit('countries/setCountries', data.countries, { root: true })
    return data
  },
}

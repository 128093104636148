export default {
  getMessageBox: (state) => {
    return state.messageBox
  },
  getHasMessage: (state) => {
    return state.hasMessage
  },
  getXeVersion: (state) => {
    return state.version
  },
  getLoadedState: (state) => {
    return state.loaded
  },
  getIsDashboardLoaded(state) {
    return state.isDashboardLoaded
  },
}

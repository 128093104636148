import router from '../../router'
import config from '@/config/register'

export default {
  resetForm({ commit, dispatch }) {
    commit('resetForm')
    dispatch('session/setSessionId', null, {
      root: true,
    })
  },
  async processLogin({ dispatch, state, getters, commit }, router) {
    const phoneDetails = getters.getPhoneDetails
    let username = phoneDetails.number
    if (state.form.infoForm[18001]) {
      username = state.form.infoForm[18001].value
    }

    commit('auth/setAlreadyAcceptedNewTerms', true, { root: true })

    await dispatch(
      'auth/login',
      {
        username: username,
        password: state.password,
        router: router,
      },
      { root: true }
    )
  },
  checkRedirectSimplifiedFlow({ rootGetters, state }) {
    let isRegistrationRoute = false
    if (router.history?.current?.matched) {
      isRegistrationRoute =
        router.history.current.matched.findIndex((route) => route.name === 'register') >= 0
    }
    if (isRegistrationRoute) {
      // Checking country to redirect simplified flow (STP)
      if (rootGetters['registration/isStpAllowedCountry']()) {
        // Disables Old Flow
        state.redirectToOldFlow = false
      } else {
        // Enables Old Flow
        state.redirectToOldFlow = true
      }
      // Push the route if needed
      const registerRouteName = rootGetters['registration/isStpAllowedCountry']()
        ? 'RegisterPersonalDetails'
        : 'RegisterBusinessInformation'
      const currentRouteName = router.history.current.name
      if (currentRouteName !== registerRouteName) {
        router.push({ name: registerRouteName })
      }
    }
  },
  initStpConfig({ commit }) {
    commit('setStpConfig', require('@/config/stpConfig'))
  },
  setSubmitRegistration({ commit }, status) {
    commit('setSubmitRegistration', status)
  },
  setRedirectToOldFlow({ commit }, status) {
    commit('setRedirectToOldFlow', status)
  },
  setFormattedAddress({ commit }, address) {
    commit('setFormattedAddress', address)
  },
  setRegistrationCompleted({ commit }, status) {
    commit('setRegistrationCompleted', status)
  },
  setRegistrationConfig({ commit }) {
    commit('setRegistrationConfig', config)
  },
}

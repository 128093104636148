import PhoneDetails from '@/models/CorporateRegistration/PhoneDetails/app'
import { map, flow, sortBy } from 'lodash-es'
/* eslint-disable */ // Remove after clean linter warnings
export default {
  activeStep(state) {
    return state.steps[state.activeStepIdx]
  },
  getStepByPath: (state) => (path) => {
    return state.steps.find((step) => {
      return step.path === path
    })
  },
  getStepIdx: (state) => (step) => {
    return state.steps.indexOf(step)
  },
  getPhoneDetails: (state, getters) => {
    const phoneDetails = state.phoneDetails
    const selectedCountryCode = getters['companyDetails/getCompanyCountry']
    if (phoneDetails && phoneDetails.number && phoneDetails.number !== phoneDetails.areaCode) {
      return new PhoneDetails({...phoneDetails})
    } else {
      const countryList = getters.getCountryList
      const country = countryList.find(country => country.code === selectedCountryCode)
      return PhoneDetails.FromCountry(country)
    }
  },
  getSelectedPhoneDetails: (state) => {
    const phoneDetails = state.phoneDetails
    if (phoneDetails) {
      return new PhoneDetails({...phoneDetails})
    }
  },
  getCompanyTypeName: (state) => {
    return state.companyTypeName
  },
  getBusinessDetail: (state) => {
    return state.businessDetail
  },
  getNewCustomerFields: (state) => (stage) => {
    if (state.newCustomerRequiredFields) {
      const corpFieldGroup = state.newCustomerRequiredFields.find(
        (group) => group.name === stage)
      return corpFieldGroup || {}
    }
    return {}
  },
  getMockedCustomerFields: (state) => (stage) => {
    if (state.mockedCustomerRequiredFields) {
      const corpFieldGroup = state.mockedCustomerRequiredFields.find(
        (group) => group.name === stage)
      return corpFieldGroup || {}
    }
    return {}
  },
  getCorporateForm: (state) => (name) => {
    const form = state.corporateForm[name]
    if (form)
      return form
    return null
  },
  getCorporateFormFieldValue: (state) => (name, id) => {
    const formField = state.corporateForm[name]
    if (formField && formField[id]) {
      return formField[id].value
    }
    return null
  },
  getCorporateFormSavedValue: (state) => (name, id) => {
    const savedFields = state.saveFormFields.filter((item) => item.group === name  && item.id === id).shift()
    return savedFields?.raw || null
  },
  getCountryList: (state) => {
    return state.countryList
  },
  getAllCountryList: (state) => {
    return state.allCountryList
  },
  getSupportCountryList: (state) => {
    return state.supportCountryList
  },
  getStateList: (state) => {
    return state.stateList
  },
  getStreetTypeList: (state) => {
    return state.streetTypeList
  },
  getNatureOfBusinessList: (state) => {
    return state.natureOfBusinessList
  },
  getSubIndustryList: (state) => {
    return state.subIndustryList
  },
  getBusinessSameOperatingAddress: (state) => {
    return state.businessSameOperatingAddress
  },
  offeredCountriesDialingCodesOptions(state, getters) {
    return flow(
      // Sort them by phonePrefix
      (countryList) => sortBy(countryList, 'phonePrefix'),
      // Map to text value pair
      (countryList) =>
        map(countryList, (country) => {
          const {phonePrefix, code, name} = country
          return {
            text: `+${phonePrefix} ${code}`,
            value: phonePrefix,
            id: code,
            nameAbbr: code,
            country: name
          }
        })
    )(getters.getSupportCountryList)
  },
  getUserId: (state) => {
    return state.userId
  },
  getPromptMarketingConsent: (state) => {
    return state.promptForMarketingConsent
  },
  getMarketingOptIn: (state) => {
    return state.marketingOptIn
  },
  getClientNumber: (state) => {
    return state.clientNumber
  },
  getCurrencyTypeList: (state) => {
    return state.currencyTypeList
  },
  getRedirectUrl: (_state, _getters, _rootState, rootGetters) => {
    const isApiIntegrationUser = rootGetters['dashboard/getCorpProfile']?.isApiUser ?? false

    if (isApiIntegrationUser) {
      return config.VUE_APP_URL_BUSINESS_CENTRAL
    }

    return config.VUE_APP_URL_TRANSFER
  },
  getSaveFormFields: (state) => {
    return state.saveFormFields
  },
  getStepperItems: (_state, _getters, rootState, _rootGetters) => {
    const steps = rootState.corporateRegistration.steps
    const activeStepIdx = rootState.corporateRegistration.activeStepIdx
    return steps.map((item, i) => {
      return {
        name: item.title,
        activeItem: i === activeStepIdx,
        isItemChecked: i < activeStepIdx,
      }
    })
  }
}
/* eslint-disable */ // Remove after clean linter warnings

<template>
  <AppCard class="card-stp">
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ stepConfig.mainTitle }}</h3>
      </AppCardHeader>
    </template>

    <!-- mobile only -->
    <h3 class="type-h2 pb-4 sm:hidden">{{ stepConfig.mainTitle }}</h3>

    <div v-if="isLoadingOptions" class="text-center text-orange">
      <AppSpinner :size="32" :loading="isLoadingOptions"></AppSpinner>
    </div>

    <div v-else>
      <XeInputDropdown
        id="companyname"
        v-debounce:800ms="onTextInput"
        data-public
        dropdown-label="Registered company legal name"
        option-label="companyName"
        :option-label-subtitle="optionLabelSubtitle"
        option-value="id"
        :show-clear="false"
        :options="companySearchResultList"
        placeholder="Enter company name or number"
        :input-editable="true"
        :show-arrow="false"
        type="company"
        :loading="showLoadingSpinner"
        :no-options-message="noOptionsMessage"
        @input="
          onOptionSelected($event, companySearchResultList, routes.nextPage, router, lastDuration)
        "
      >
        <template #custom-dropdown-footer-option>
          <div v-if="searchPerformed" class="custom-dropdown-footer">
            Don't see your company?
            <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
            <a
              class="custom-footer-button"
              @click="onEnterManuallyClick('Dropdown', routes.manuallyClick, router)"
              >Enter it manually</a
            >
          </div>
        </template>
      </XeInputDropdown>
    </div>
  </AppCard>
</template>

<script>
import { reactive, onBeforeMount, ref } from '@vue/composition-api'

import { storeToRefs } from 'pinia'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import {
  onOptionSelected,
  setOptionLabelSubtitle,
  onEnterManuallyClick,
} from '@/composables/useSearch'

import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import AppSpinner from '@/components/AppSpinner/AppSpinner'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'RegisterBusinessSearchSTP',
  components: {
    AppCard,
    AppCardHeader,
    AppIcon,
    AppButton,
    IconChevronLeft,
    AppSpinner,
    XeInputDropdown,
  },
  setup() {
    const router = useRouter()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    // Setup default variables
    const isLoadingOptions = ref(true)
    const noOptionsMessageChoices = ref([])
    const searchPerformed = ref(false)
    const companySearchResultList = ref([])
    const showLoadingSpinner = ref(false)
    const noOptionsMessage = ref(noOptionsMessageChoices[0])
    const optionLabelSubtitle = ref('id')
    const { companyCountry } = storeToRefs(companyDetailsStore)
    const lastSearch = ref('')
    const lastDuration = ref(0)
    const routes = ref({})

    // Get STP config
    const stepConfig = registrationStore.getRegistrationStepConfigById('businessSearch')

    // Setup everything to render properly
    onBeforeMount(async () => {
      routes.value = stepConfig.routes

      corporateRegistrationStore.setActiveStep(router.currentRoute.path)
      corporateRegistrationStore.activeStepIdx = stepConfig.stepId
      corporateRegistrationStore.setActiveStepPageTitle(stepConfig.stepTitle)
      // Set dropdown config
      noOptionsMessageChoices.value = Object.freeze(stepConfig.searchMessages)
      // Get corp BFF fields
      if (!corporateRegistrationStore.userId) {
        await corporateRegistrationStore.fetchNewCustomerRequiredFields()
      }
      isLoadingOptions.value = false
    })

    const activeStep = corporateRegistrationStore.activeStep
    const goBack = () => router.push(routes.value.backButton)

    const onTextInput = async (query) => {
      // Get query
      query = query.trim()
      // Set default values
      companySearchResultList.value = []
      searchPerformed.value = false
      noOptionsMessage.value = ''
      lastSearch.value = query
      // Call the name search endpoint after length is greater than 4
      if (query.length >= 4) {
        showLoadingSpinner.value = true
        const date = new Date()
        try {
          const result = await companyDetailsStore.getCompanyNameSearchResult({
            name: query,
            country: companyCountry.value,
          })
          // Set option label subtitle
          const optionLabelSubtitleResult = setOptionLabelSubtitle(companyCountry.value, result)
          if (optionLabelSubtitleResult) {
            optionLabelSubtitle.value = optionLabelSubtitleResult
          }
          if (lastSearch.value === query) {
            lastDuration.value = Math.floor(new Date().getTime() - date.getTime())
            companySearchResultList.value = result
            showLoadingSpinner.value = false
            searchPerformed.value = true
            if (companySearchResultList.value.length === 0) {
              noOptionsMessage.value = noOptionsMessageChoices.value[1]
            }
          }
        } catch (ex) {
          appStore.logException({
            text: 'Exception during searching for company',
            exception: ex,
          })
          if (lastSearch.value === query) {
            showLoadingSpinner.value = false
            searchPerformed.value = true
            noOptionsMessage.value = noOptionsMessageChoices.value[1]
          }
        }
      } else {
        noOptionsMessage.value = noOptionsMessageChoices.value[0]
        showLoadingSpinner.value = false
        searchPerformed.value = false
      }
    }

    return {
      mq: reactive(useMediaQuery()),
      activeStep,
      noOptionsMessageChoices,
      companySearchResultList,
      showLoadingSpinner,
      noOptionsMessage,
      searchPerformed,
      setOptionLabelSubtitle,
      onOptionSelected,
      isLoadingOptions,
      optionLabelSubtitle,
      onEnterManuallyClick,
      lastDuration,
      stepConfig,
      routes,
      router,
      onTextInput,
      goBack,
    }
  },
}
</script>

<style lang="postcss" scoped>
.manually-enter-section {
  @apply flex justify-center;
  @apply text-base;

  .button {
    @apply ml-1;

    ::v-deep &.button--text {
      .button-inner {
        @apply p-0;
      }
    }

    &:focus,
    &:hover {
      @apply bg-transparent;
    }
  }
}

.custom-dropdown-footer {
  @apply px-4 py-2.5;
  @apply type-caption;
  @apply cursor-default;

  .custom-footer-button {
    @apply text-blue;
    @apply cursor-pointer;
  }
}
</style>

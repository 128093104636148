import companyType from '@/api/corporate/companyType/get'
import companyNameSearch from '@/api/corporate/companySearch/get'
import { newRegNumber } from '@/utils/prefix'
import { getMappedCountry } from '@/utils/i18n'

export default {
  setCompanyCountry({ commit, dispatch }, country) {
    commit('setCompanyCountry', country)
    if (country) {
      dispatch('getCompanyTypeList', country)
    }
  },
  setCompanyType({ commit }, type) {
    commit('setCompanyType', type)
  },
  setHasCompanyTypeChanged({ commit }, status) {
    commit('setHasCompanyTypeChanged', status)
  },
  setCompanyDetails({ commit }, companyDetails) {
    commit('setCompanyDetails', companyDetails)
  },
  setCompanyAddress({ commit }, businessAddress) {
    commit('setCompanyAddress', businessAddress)
  },
  setCompanyOperatingAddress({ commit }, companyOperatingAddr) {
    commit('setCompanyOperatingAddress', companyOperatingAddr)
  },
  async getCompanyTypeList({ commit }, country) {
    const { data } = await companyType.exec({ country })
    if (data) {
      commit('setCompanyTypeList', data)
    }
  },
  async getCompanyNameSearchResult(context, { name, country }) {
    const newCountry = await getMappedCountry(country)
    const newName = await newRegNumber(name, newCountry)
    const { data } = await companyNameSearch.exec({ name: newName, country: newCountry })
    if (data) {
      return data
    }
  },
  resetForm({ commit }) {
    commit('resetForm')
  },
}

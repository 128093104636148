export default {
  setSession: (state, obj) => {
    state.session = obj
  },
  setSessionId(state, sessionId) {
    state.sessionId = sessionId
  },
  setLogRocketSessionUrl: (state, url) => {
    state.logRocketSessionUrl = url
  },
}

import router from '@/router'

export default {
  async messageBoxGenericError() {
    router.push({
      name: 'RegisterError',
      props: {
        errorType: 'general',
      },
    })
  },
  async hideMessageBox({ commit }) {
    commit('setHasMessage', false)
  },
  async logException({ dispatch }, { text, exception, fireEvent }) {
    // TODO: Update how we need to handle app exceptions beside doing a console.error
    // https://euronet.atlassian.net/wiki/spaces/XSS/pages/2694545409/Corp+Reg+-+Front+End+Tech+Debt
    // eslint-disable-next-line no-console
    console.error('EXCEPTION: ' + text)
    // eslint-disable-next-line no-console
    console.error(exception)
    if (fireEvent) {
      dispatch(
        'analytics/track',
        { event: text, traits: { exceptionMessage: exception } },
        { root: true }
      )
    }
  },
  async setup({ dispatch, rootGetters }) {
    await dispatch('resources/getSystemFields', rootGetters['device/getCountry'], { root: true })
  },
  async setVersion({ commit }) {
    const xeVersion = config.VUE_APP_XE_VERSION || 'development'
    commit('setXeVersion', xeVersion)
  },
  setLoadedState({ commit }, value) {
    commit('setLoadedState', value)
  },
  setIsDashboardLoaded({ commit }, isDashboardLoaded) {
    commit('setIsDashboardLoaded', isDashboardLoaded)
  },
}

export default {
  setCompanyTypeList(state, companyTypeList) {
    state.companyTypeList = companyTypeList;
  },
  setCompanyCountry(state, country) {
    state.companyCountry = country
  },
  setCompanyType(state, type) {
    state.companyType = type
  },
  setHasCompanyTypeChanged(state, status) {
    state.hasCompanyTypeChanged = status
  },
  setCompanyDetails(state, companyDetails) {
    state.companyDetails = companyDetails
  },
  setCompanyAddress(state, companyAddress) {
    state.companyAddress = companyAddress
  },
  setCompanyOperatingAddress(state, companyOperatingAddress) {
    state.companyOperatingAddress = companyOperatingAddress
  },
  resetForm(state) {
    state.companyCountry = ''
    state.companyType = ''
    state.companyDetails = {}

    state.companyAddress.id = ''
    state.companyAddress.companyName = ''
    state.companyAddress.streetNumber = ''
    state.companyAddress.streetName = ''
    state.companyAddress.addressLine1 = ''
    state.companyAddress.addressLine2 = ''
    state.companyAddress.addressLine3 = ''
    state.companyAddress.city = ''
    state.companyAddress.area = ''
    state.companyAddress.countryCode = ''
    state.companyAddress.postCode = ''
    state.companyAddress.state = ''
    state.companyAddress.county = ''
    state.companyAddress.consolidationCode = ''
    state.companyAddress.naceCode = ''
    state.companyAddress.naceLabel = ''
    state.companyAddress.incorporationDate = ''
    state.companyAddress.nationalId = []
    state.companyAddress.vatNumber = []
    state.companyAddress.website = []

    state.companyOperatingAddress.id = ''
    state.companyOperatingAddress.companyName = ''
    state.companyOperatingAddress.streetNumber = ''
    state.companyOperatingAddress.streetName = ''
    state.companyOperatingAddress.streetType = ''
    state.companyOperatingAddress.addressLine1 = ''
    state.companyOperatingAddress.addressLine2 = ''
    state.companyOperatingAddress.addressLine3 = ''
    state.companyOperatingAddress.city = ''
    state.companyOperatingAddress.area = ''
    state.companyOperatingAddress.countryCode = ''
    state.companyOperatingAddress.postCode = ''
    state.companyOperatingAddress.state = ''
    state.companyOperatingAddress.county = ''
    state.companyOperatingAddress.consolidationCode = ''
    state.companyOperatingAddress.naceCode = ''
    state.companyOperatingAddress.naceLabel = ''
    state.companyOperatingAddress.incorporationDate = ''
    state.companyOperatingAddress.nationalId = []
    state.companyOperatingAddress.vatNumber = []
    state.companyOperatingAddress.website = []

    state.signingAuthority = false
    state.subIndustryList = []
  },
}

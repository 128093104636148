export default {
  resetForm(state) {
    state.stpConfig = {}
    state.formattedAddress = ''
    state.isSubmitRegistration = false
    state.profileId = 0
    state.email = ''
    state.password = ''
    state.activeStepIdx = 0
    state.form.firstName = ''
    state.form.lastName = ''
    state.form.dateOfBirth = ''
    state.form.mobilePhone = ''
    state.form.mobilePrefix = ''
    state.form.addressLine1 = ''
    state.form.place = ''
    state.form.postalCode = ''
    state.form.unit = ''
    state.form.streetNumber = ''
    state.form.streetName = ''
    state.form.streetType = ''
    state.form.state = ''
    state.form.country = 'US' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  },
  setStpConfig(state, items) {
    state.stpConfig = items
  },
  setSubmitRegistration(state, status) {
    state.isSubmitRegistration = status
  },
  setRedirectToOldFlow(state, status) {
    state.redirectToOldFlow = status
  },
  setFormattedAddress(state, address) {
    state.formattedAddress = address
  },
  setRegistrationCompleted(state, status) {
    state.registrationCompleted = status
  },
  setRegistrationConfig(state, config) {
    state.registrationConfig = config
  },
}

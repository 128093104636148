import {
  analyticsInit,
  identify,
  gtmTrack,
  page,
  track
} from '../../composables/useAnalytics/index'

export default {
  async init({ commit }, { ...analyticsKeys }) {
    await analyticsInit({ ...analyticsKeys })
  },
  async identify({ commit }, { userId, traits }) {
    await identify({ userId, traits })
  },
  async track({ commit }, { event, traits }) {
    await track({ event, traits })
  },
  async gtmTrack({ commit }, { event, variables}) {
    await gtmTrack({ event, variables})
  }
}

import { useCorporateRegistrationStore } from '../../stores/corporateRegistration'
import { fixStreetAddressAU } from './useAU'
import { fixStreetAddressCA } from './useCA'
import { fixStreetAddressMY } from './useMY'
import { fixStreetAddressNZ } from './useNZ'

//capitalize the first letter of every word
const capitalize = (str) => {
  if (!str) {
    return ''
  }
  return str
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

export const fixStreetAddress = async (companyCountry, selectedCompany) => {
  const corporateRegistrationStore = useCorporateRegistrationStore()
  const state = await corporateRegistrationStore.mapStateValue(selectedCompany.state)
  const addressObject = {
    address: '',
    addressLine1: capitalize(selectedCompany.addressLine1),
    addressLine2: capitalize(selectedCompany.addressLine2),
    addressLine3: capitalize(selectedCompany.addressLine3),
    unit: '',
    streetnumber: '',
    streetname: capitalize(selectedCompany.addressLine1),
    city: capitalize(selectedCompany.city),
    state: state ? state : capitalize(selectedCompany.county) || '',
    postCode: selectedCompany.postCode ? selectedCompany.postCode.toUpperCase() : '',
  }

  try {
    if (companyCountry === 'MY') {
      return await fixStreetAddressMY(addressObject)
    }
    if (companyCountry === 'CA') {
      return await fixStreetAddressCA(addressObject)
    }
    if (companyCountry === 'AU') {
      return fixStreetAddressAU(addressObject)
    }
    if (companyCountry === 'NZ') {
      return fixStreetAddressNZ(addressObject)
    }

    return addressObject
  } catch (error) {
    return addressObject
  }
}

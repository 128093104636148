export default {
  steps: [
    {
      title: 'Account',
      path: '/registration',
      pageTitle: 'Create an account',
      titleKey: 'PageRegister.StepAccountTitle',
      pageTitleKey: 'PageRegister.StepAccountPageTitle',
      // $t('PageRegister.StepAccountTitle')
      // $t('PageRegister.StepAccountPageTitle')
    },
    {
      title: 'Verification',
      path: '/registration/verification',
      pageTitle: 'Verification',
      titleKey: 'PageRegister.StepVerificationTitle',
      pageTitleKey: 'PageRegister.StepVerificationPageTitle',
      // $t('PageRegister.StepVerificationTitle')
      // $t('PageRegister.StepVerificationPageTitle')
    },
    {
      title: 'Info',
      path: '/registration/info',
      pageTitle: 'Personal information',
      titleKey: 'PageRegister.StepInfoTitle',
      pageTitleKey: 'PageRegister.StepInfoPageTitle',
      // $t('PageRegister.StepInfoTitle')
      // $t('PageRegister.StepInfoPageTitle')
    },
    {
      title: 'Address',
      path: '/registration/address',
      pageTitle: 'Your address',
      titleKey: 'PageRegister.StepAddressTitle',
      pageTitleKey: 'PageRegister.StepAddressPageTitle',
      // $t('PageRegister.StepAddressTitle')
      // $t('PageRegister.StepAddressPageTitle')
    },
  ],
  activeStepIdx: 0,
  profileId: 0,
  email: '',
  password: '',
  isSubmitRegistration: false,
  form: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    mobilePhone: '',
    mobilePrefix: '+1', // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    addressLine1: '',
    place: '',
    postalCode: '',
    unit: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    state: '',
    country: 'US', // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  },
  stpConfig: {},
  // Manual switch to allow stp evaluation by country/form filled
  redirectToOldFlow: true,
  formattedAddress: '',
  registrationCompleted: false,
  registrationConfig: {},
}
